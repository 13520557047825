import React from "react"
import { Table } from 'react-bootstrap'

import "./index.css";
import { AppState } from "@declarations";

interface Props {
    activity: AppState.EmotionsTableRow[]
}
export const EmotionsTable: React.FC<Props> = (props) => {

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Age</th>
                    <th>Gender</th>
                    <th>Smile</th>
                    <th>Anger</th>
                    <th>Contempt</th>
                    <th>Disgust</th>
                    <th>Fear</th>
                    <th>Happiness</th>
                    <th>Neutral</th>
                    <th>Sadness</th>
                    <th>Surprise</th>
                </tr>
            </thead>
            <tbody>
                {
                    props.activity.map((row, index) => (
                        <tr key={"unit-1-" + index.toString()}>
                            <td>{row.Date.toLocaleDateString()}</td>
                            <td>{row.Age ? row.Age.toFixed(0) : 0}</td>
                            <td>{row.Gender ? row.Gender : "N/A"}</td>
                            <td>{row.Smile ? row.Smile.toFixed(4) : 0}</td>
                            <td>{row.Anger ? row.Anger.toFixed(4) : 0}</td>
                            <td>{row.Contempt ? row.Contempt.toFixed(4) : 0}</td>
                            <td>{row.Disgust ? row.Disgust.toFixed(4) : 0}</td>
                            <td>{row.Fear ? row.Fear.toFixed(4) : 0}</td>
                            <td>{row.Happiness ? row.Happiness.toFixed(4) : 0}</td>
                            <td>{row.Neutral ? row.Neutral.toFixed(4) : 0}</td>
                            <td>{row.Sadness ? row.Sadness.toFixed(4) : 0}</td>
                            <td>{row.Surprise ? row.Surprise.toFixed(4) : 0}</td>
                        </tr>
                    ))}
            </tbody>
        </Table>
    )

}

