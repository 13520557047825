
import { put } from 'redux-saga/effects'
import { ReduxAction, AppState, Session } from '@declarations';
import { Actions, makeAction } from '../Store/actions';
import { store } from '../Store';
import { getEmbed, getExperiencesSummary } from '../Utils/RemoteCalls';


type ExpSummary = Omit<AppState.Experience, 'brand'> & {
    channel: {
        name: string,
        brand: { name: string }
    }
}
const excludeList = new Set([
    "Example",
    "DevJustin",
    "devScott",
    "NexTech Sales",
    "NexTech AR Solutions Corp",
    "Developer Portal",
    "devContent",
    "devIan"
])

export function* getExperiences(/** { payload }: ReduxAction<{ brand: string } | { experience: string }> */) {
    // if (!payload) throw new Error("Get Embeds should be called with parent brand or experience id")
    const data: ExpSummary[] = yield getExperiencesSummary();
    const brandMap = data.filter(experience => {
        const session = (store.getState().session as Session.AuthSession);
        if (session.user?.admin) return true;
        return experience.channel.brand.name === session.user.brandName
    }).reduce((brands, experience) => {
        const channel = experience.channel;
        const brandName = channel.brand.name;
        const channelsMap = brands[brandName] || {};
        const exps: AppState.Experience[] = channelsMap[channel.name] || [];

        if (excludeList.has(brandName)) return brands;

        exps.push({ name: experience.name, brand: brandName, channel: channel.name, id: experience.id });
        channelsMap[channel.name] = exps;
        brands[brandName] = channelsMap;
        return brands;
    }, {} as Record<string, Record<string, AppState.Experience[]>>);

    const brands: AppState.AppBrand[] = Object.keys(brandMap).map<AppState.AppBrand>(brandName => {
        const channelsMap = brandMap[brandName];
        return {
            name: brandName,
            channels: Object.keys(channelsMap).map<AppState.Channel>(channelName => {
                return {
                    experiences: channelsMap[channelName].sort((a, b) => {
                        if (a.name === b.name) return 0;
                        if (a.name > b.name) return 1;
                        return -1;
                    }), name: channelName
                }
            }).sort((a, b) => {
                if (a.name === b.name) return 0;
                if (a.name > b.name) return 1;
                return -1;
            })
        };
    }).sort((a, b) => {
        if (a.name === b.name) return 0;
        if (a.name > b.name) return 1;
        return -1;
    });
    console.log("===brands", brands)
    yield put(makeAction(Actions.SET_APP_BRANDS_LIST, brands));
}

