import React from "react";
import "./index.css";

export const Footer: React.FC = () => {
    return (
        <footer className="page-footer font-small unique-color-dark pt-4">
        <div className="footer-copyright text-center py-3">© 2020 Copyright
        <a href="https://nextechar.com/"> NexTech AR Solutions Inc.</a>
        </div>
      </footer>
    )
}