import { ReduxAction, AppState, ActionPayloads, Session } from "@declarations"
import { AppDataParams, AppActivityParams, AdsDataParams, WithScope } from "../Values/xapiKeys"
export enum Actions {

  //ADS
  GET_ADS_ACTIVITY = "GET_ADS_ACTIVITY",
  SET_ADS_ACTIVITY = "SET_ADS_ACTIVITY",
  GET_ADS_OVERVIEW = "GET_ADS_OVERVIEW",
  SET_ADS_OVERVIEW = "SET_ADS_OVERVIEW",

  // AD UNITS
  GET_ADS_ACTIVITY_UNIT_ONE = "GET_ADS_ACTIVITY_UNIT_ONE",
  GET_ADS_ACTIVITY_UNIT_TWO = "GET_ADS_ACTIVITY_UNIT_TWO",
  GET_ADS_ACTIVITY_UNIT_THREE = "GET_ADS_ACTIVITY_UNIT_THREE",

  // AD UNITS AND BREAKDOWNS
  GET_UNIT_ONE_IMPRESSIONS = "GET_UNIT_ONE_IMPRESSIONS",
  SET_UNIT_ONE_IMPRESSIONS = "SET_UNIT_ONE_IMPRESSIONS",
  GET_UNIT_ONE_OPENS = "GET_UNIT_ONE_OPENS",
  SET_UNIT_ONE_OPENS = "SET_UNIT_ONE_OPENS",
  GET_UNIT_ONE_TIME_TO_OPEN = "GET_UNIT_ONE_TIME_TO_OPEN",
  SET_UNIT_ONE_TIME_TO_OPEN = "SET_UNIT_ONE_TIME_TO_OPEN",
  GET_UNIT_ONE_CLICKS = "GET_UNIT_ONE_CLICKS",
  SET_UNIT_ONE_CLICKS = "SET_UNIT_ONE_CLICKS",
  GET_UNIT_ONE_TIME_IN_AR = "GET_UNIT_ONE_TIME_IN_AR",
  SET_UNIT_ONE_TIME_IN_AR = "SET_UNIT_ONE_TIME_IN_AR",
  GET_UNIT_ONE_EMOTIONS = "GET_UNIT_ONE_EMOTIONS",
  SET_UNIT_ONE_EMOTIONS = "SET_UNIT_ONE_EMOTIONS",
  // GET_UNIT_ONE_AGE = "GET_UNIT_ONE_AGE",
  // SET_UNIT_ONE_AGE = "SET_UNIT_ONE_AGE",
  // GET_UNIT_ONE_MALE = "GET_UNIT_ONE_MALE",
  // SET_UNIT_ONE_MALE = "SET_UNIT_ONE_MALE",
  // GET_UNIT_ONE_FEMALE = "GET_UNIT_ONE_FEMALE",
  // SET_UNIT_ONE_FEMALE = "SET_UNIT_ONE_FEMALE",
  // GET_UNIT_ONE_SMILE = "GET_UNIT_ONE_SMILE",
  // SET_UNIT_ONE_SMILE = "SET_UNIT_ONE_SMILE",
  // GET_UNIT_ONE_ANGER = "GET_UNIT_ONE_ANGER",
  // SET_UNIT_ONE_ANGER = "SET_UNIT_ONE_ANGER",
  // GET_UNIT_ONE_CONTEMPT = "GET_UNIT_ONE_CONTEMPT",
  // SET_UNIT_ONE_CONTEMPT = "SET_UNIT_ONE_CONTEMPT",
  // GET_UNIT_ONE_DISGUST = "GET_UNIT_ONE_DISGUST",
  // SET_UNIT_ONE_DISGUST = "SET_UNIT_ONE_DISGUST",
  // GET_UNIT_ONE_FEAR = "GET_UNIT_ONE_FEAR",
  // SET_UNIT_ONE_FEAR = "SET_UNIT_ONE_FEAR",
  // GET_UNIT_ONE_HAPPINESS = "GET_UNIT_ONE_HAPPINESS",
  // SET_UNIT_ONE_HAPPINESS = "SET_UNIT_ONE_HAPPINESS",
  // GET_UNIT_ONE_NEUTRAL = "GET_UNIT_ONE_NEUTRAL",
  // SET_UNIT_ONE_NEUTRAL = "SET_UNIT_ONE_NEUTRAL",
  // GET_UNIT_ONE_SADNESS = "GET_UNIT_ONE_SADNESS",
  // SET_UNIT_ONE_SADNESS = "SET_UNIT_ONE_SADNESS",
  // GET_UNIT_ONE_SURPRISE = "GET_UNIT_ONE_SURPRISE",
  // SET_UNIT_ONE_SURPRISE = "SET_UNIT_ONE_SURPRISE",
  GET_UNIT_ONE_AD_INTERACTIONS_FRAME_ONE = "GET_UNIT_ONE_AD_INTERACTIONS_FRAME_ONE",
  SET_UNIT_ONE_AD_INTERACTIONS_FRAME_ONE = "SET_UNIT_ONE_AD_INTERACTIONS_FRAME_ONE",
  GET_UNIT_ONE_AD_INTERACTIONS_FRAME_TWO = "GET_UNIT_ONE_AD_INTERACTIONS_FRAME_TWO",
  SET_UNIT_ONE_AD_INTERACTIONS_FRAME_TWO = "SET_UNIT_ONE_AD_INTERACTIONS_FRAME_TWO",
  GET_UNIT_ONE_AD_INTERACTIONS_FRAME_THREE = "GET_UNIT_ONE_AD_INTERACTIONS_FRAME_THREE",
  SET_UNIT_ONE_AD_INTERACTIONS_FRAME_THREE = "SET_UNIT_ONE_AD_INTERACTIONS_FRAME_THREE",
  GET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_ONE = "GET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_ONE",
  SET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_ONE = "SET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_ONE",
  GET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_TWO = "GET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_TWO",
  SET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_TWO = "SET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_TWO",
  GET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_THREE = "GET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_THREE",
  SET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_THREE = "SET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_THREE",

  GET_UNIT_TWO_IMPRESSIONS = "GET_UNIT_TWO_IMPRESSIONS",
  SET_UNIT_TWO_IMPRESSIONS = "SET_UNIT_TWO_IMPRESSIONS",
  GET_UNIT_TWO_OPENS = "GET_UNIT_TWO_OPENS",
  SET_UNIT_TWO_OPENS = "SET_UNIT_TWO_OPENS",
  GET_UNIT_TWO_TIME_TO_OPEN = "GET_UNIT_TWO_TIME_TO_OPEN",
  SET_UNIT_TWO_TIME_TO_OPEN = "SET_UNIT_TWO_TIME_TO_OPEN",
  GET_UNIT_TWO_CLICKS = "GET_UNIT_TWO_CLICKS",
  SET_UNIT_TWO_CLICKS = "SET_UNIT_TWO_CLICKS",
  GET_UNIT_TWO_TIME_IN_AR = "GET_UNIT_TWO_TIME_IN_AR",
  SET_UNIT_TWO_TIME_IN_AR = "SET_UNIT_TWO_TIME_IN_AR",
  GET_UNIT_TWO_EMOTIONS = "GET_UNIT_TWO_EMOTIONS",
  SET_UNIT_TWO_EMOTIONS = "SET_UNIT_TWO_EMOTIONS",
  // GET_UNIT_TWO_AGE = "GET_UNIT_TWO_AGE",
  // SET_UNIT_TWO_AGE = "SET_UNIT_TWO_AGE",
  // GET_UNIT_TWO_MALE = "GET_UNIT_TWO_MALE",
  // SET_UNIT_TWO_MALE = "SET_UNIT_TWO_MALE",
  // GET_UNIT_TWO_FEMALE = "GET_UNIT_TWO_FEMALE",
  // SET_UNIT_TWO_FEMALE = "SET_UNIT_TWO_FEMALE",
  // GET_UNIT_TWO_SMILE = "GET_UNIT_TWO_SMILE",
  // SET_UNIT_TWO_SMILE = "SET_UNIT_TWO_SMILE",
  // GET_UNIT_TWO_ANGER = "GET_UNIT_TWO_ANGER",
  // SET_UNIT_TWO_ANGER = "SET_UNIT_TWO_ANGER",
  // GET_UNIT_TWO_CONTEMPT = "GET_UNIT_TWO_CONTEMPT",
  // SET_UNIT_TWO_CONTEMPT = "SET_UNIT_TWO_CONTEMPT",
  // GET_UNIT_TWO_DISGUST = "GET_UNIT_TWO_DISGUST",
  // SET_UNIT_TWO_DISGUST = "SET_UNIT_TWO_DISGUST",
  // GET_UNIT_TWO_FEAR = "GET_UNIT_TWO_FEAR",
  // SET_UNIT_TWO_FEAR = "SET_UNIT_TWO_FEAR",
  // GET_UNIT_TWO_HAPPINESS = "GET_UNIT_TWO_HAPPINESS",
  // SET_UNIT_TWO_HAPPINESS = "SET_UNIT_TWO_HAPPINESS",
  // GET_UNIT_TWO_NEUTRAL = "GET_UNIT_TWO_NEUTRAL",
  // SET_UNIT_TWO_NEUTRAL = "SET_UNIT_TWO_NEUTRAL",
  // GET_UNIT_TWO_SADNESS = "GET_UNIT_TWO_SADNESS",
  // SET_UNIT_TWO_SADNESS = "SET_UNIT_TWO_SADNESS",
  // GET_UNIT_TWO_SURPRISE = "GET_UNIT_TWO_SURPRISE",
  // SET_UNIT_TWO_SURPRISE = "SET_UNIT_TWO_SURPRISE",
  GET_UNIT_TWO_AD_INTERACTIONS_FRAME_ONE = "GET_UNIT_TWO_AD_INTERACTIONS_FRAME_ONE",
  SET_UNIT_TWO_AD_INTERACTIONS_FRAME_ONE = "SET_UNIT_TWO_AD_INTERACTIONS_FRAME_ONE",
  GET_UNIT_TWO_AD_INTERACTIONS_FRAME_TWO = "GET_UNIT_TWO_AD_INTERACTIONS_FRAME_TWO",
  SET_UNIT_TWO_AD_INTERACTIONS_FRAME_TWO = "SET_UNIT_TWO_AD_INTERACTIONS_FRAME_TWO",
  GET_UNIT_TWO_AD_INTERACTIONS_FRAME_THREE = "GET_UNIT_TWO_AD_INTERACTIONS_FRAME_THREE",
  SET_UNIT_TWO_AD_INTERACTIONS_FRAME_THREE = "SET_UNIT_TWO_AD_INTERACTIONS_FRAME_THREE",
  GET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_ONE = "GET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_ONE",
  SET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_ONE = "SET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_ONE",
  GET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_TWO = "GET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_TWO",
  SET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_TWO = "SET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_TWO",
  GET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_THREE = "GET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_THREE",
  SET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_THREE = "SET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_THREE",

  GET_UNIT_THREE_IMPRESSIONS = "GET_UNIT_THREE_IMPRESSIONS",
  SET_UNIT_THREE_IMPRESSIONS = "SET_UNIT_THREE_IMPRESSIONS",
  GET_UNIT_THREE_OPENS = "GET_UNIT_THREE_OPENS",
  SET_UNIT_THREE_OPENS = "SET_UNIT_THREE_OPENS",
  GET_UNIT_THREE_TIME_TO_OPEN = "GET_UNIT_THREE_TIME_TO_OPEN",
  SET_UNIT_THREE_TIME_TO_OPEN = "SET_UNIT_THREE_TIME_TO_OPEN",
  GET_UNIT_THREE_CLICKS = "GET_UNIT_THREE_CLICKS",
  SET_UNIT_THREE_CLICKS = "SET_UNIT_THREE_CLICKS",
  GET_UNIT_THREE_TIME_IN_AR = "GET_UNIT_THREE_TIME_IN_AR",
  SET_UNIT_THREE_TIME_IN_AR = "SET_UNIT_THREE_TIME_IN_AR",
  GET_UNIT_THREE_EMOTIONS = "GET_UNIT_THREE_EMOTIONS",
  SET_UNIT_THREE_EMOTIONS = "SET_UNIT_THREE_EMOTIONS",
  // GET_UNIT_THREE_AGE = "GET_UNIT_THREE_AGE",
  // SET_UNIT_THREE_AGE = "SET_UNIT_THREE_AGE",
  // GET_UNIT_THREE_MALE = "GET_UNIT_THREE_MALE",
  // SET_UNIT_THREE_MALE = "SET_UNIT_THREE_MALE",
  // GET_UNIT_THREE_FEMALE = "GET_UNIT_THREE_FEMALE",
  // SET_UNIT_THREE_FEMALE = "SET_UNIT_THREE_FEMALE",
  // GET_UNIT_THREE_SMILE = "GET_UNIT_THREE_SMILE",
  // SET_UNIT_THREE_SMILE = "SET_UNIT_THREE_SMILE",
  // GET_UNIT_THREE_ANGER = "GET_UNIT_THREE_ANGER",
  // SET_UNIT_THREE_ANGER = "SET_UNIT_THREE_ANGER",
  // GET_UNIT_THREE_CONTEMPT = "GET_UNIT_THREE_CONTEMPT",
  // SET_UNIT_THREE_CONTEMPT = "SET_UNIT_THREE_CONTEMPT",
  // GET_UNIT_THREE_DISGUST = "GET_UNIT_THREE_DISGUST",
  // SET_UNIT_THREE_DISGUST = "SET_UNIT_THREE_DISGUST",
  // GET_UNIT_THREE_FEAR = "GET_UNIT_THREE_FEAR",
  // SET_UNIT_THREE_FEAR = "SET_UNIT_THREE_FEAR",
  // GET_UNIT_THREE_HAPPINESS = "GET_UNIT_THREE_HAPPINESS",
  // SET_UNIT_THREE_HAPPINESS = "SET_UNIT_THREE_HAPPINESS",
  // GET_UNIT_THREE_NEUTRAL = "GET_UNIT_THREE_NEUTRAL",
  // SET_UNIT_THREE_NEUTRAL = "SET_UNIT_THREE_NEUTRAL",
  // GET_UNIT_THREE_SADNESS = "GET_UNIT_THREE_SADNESS",
  // SET_UNIT_THREE_SADNESS = "SET_UNIT_THREE_SADNESS",
  // GET_UNIT_THREE_SURPRISE = "GET_UNIT_THREE_SURPRISE",
  // SET_UNIT_THREE_SURPRISE = "SET_UNIT_THREE_SURPRISE",
  GET_UNIT_THREE_AD_INTERACTIONS_FRAME_ONE = "GET_UNIT_THREE_AD_INTERACTIONS_FRAME_ONE",
  SET_UNIT_THREE_AD_INTERACTIONS_FRAME_ONE = "SET_UNIT_THREE_AD_INTERACTIONS_FRAME_ONE",
  GET_UNIT_THREE_AD_INTERACTIONS_FRAME_TWO = "GET_UNIT_THREE_AD_INTERACTIONS_FRAME_TWO",
  SET_UNIT_THREE_AD_INTERACTIONS_FRAME_TWO = "SET_UNIT_THREE_AD_INTERACTIONS_FRAME_TWO",
  GET_UNIT_THREE_AD_INTERACTIONS_FRAME_THREE = "GET_UNIT_THREE_AD_INTERACTIONS_FRAME_THREE",
  SET_UNIT_THREE_AD_INTERACTIONS_FRAME_THREE = "SET_UNIT_THREE_AD_INTERACTIONS_FRAME_THREE",
  GET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_ONE = "GET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_ONE",
  SET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_ONE = "SET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_ONE",
  GET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_TWO = "GET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_TWO",
  SET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_TWO = "SET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_TWO",
  GET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_THREE = "GET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_THREE",
  SET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_THREE = "SET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_THREE",

  GET_OVERVIEW_DATA = "GET_OVERVIEW_DATA",
  GET_PERIODIC_DATA = "GET_PERIODIC_DATA",
  GET_BRANDS = "GET_BRANDS",
  // GET_BRAND_DETAILS = "GET_BRAND_DETAILS",
  GET_EMBEDS = "GET_EMBEDS",
  GET_EMBED_DETAILS = "GET_EMBED_DETAILS",
  SET_OVERVIEW_WAIT_STATE = "SET_OVERVIEW_WAIT_STATE",
  SET_PERIODIC_DATA_WAIT_STATE = "SET_PERIODIC_DATA_WAIT_STATE",
  SET_BRANDS_LIST = "SET_BRANDS_LIST",
  SET_EMBEDS_LIST = "SET_EMBEDS_LIST",
  SET_DASHBOARD_OVERVIEW_DATA = "SET_DASHBOARD_OVERVIEW_DATA",
  SET_DASHBOARD_PERIODIC_DATA = "SET_DASHBOARD_PERIODIC_DATA",
  SET_CURRENT_EMBED = "SET_CURRENT_EMBED",
  SET_CURRENT_BRAND = "SET_CURRENT_BRAND",
  //App Dashboard
  GET_EXPERIENCE_LIST = "GET_EXPERIENCE_LIST",
  GET_EXP_BRAND_LIST = "GET_EXP_BRAND_LIST",
  GET_APP_OVERVIEW = "GET_APP_OVERVIEW",
  GET_APP_ACTIVITY = "GET_APP_ACTIVITY",
  SET_APP_OVERVIEW = "SET_APP_OVERVIEW",
  SET_APP_ACTIVITY = "SET_APP_ACTIVITY",
  SET_APP_BRANDS_LIST = "SET_APP_BRANDS_LIST",
  SET_CURRENT_EXPERIENCE = "SET_CURRENT_EXPERIENCE",
  SET_EXPERIENCE_LIST = "SET_EXPERIENCE_LIST",
  SET_CURRENT_APP_BRAND = "SET_CURRENT_APP_BRAND",
  SET_CURRENT_CHANNEL = "SET_CURRENT_CHANNEL",
  SET_EXP_IDS = "SET_EXP_IDS",
  //session
  LOGIN = "LOGIN",
  UPDATE_SESSION = "UPDATE_SESSION",
  SET_SESSION = "SET_SESSION",
  SET_NOT_FOUND = "SET_NOT_FOUND"
}


//overloads


// ADS AND BREAKDOWNS
export function makeAction(type: Actions.GET_UNIT_ONE_IMPRESSIONS, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_ONE_IMPRESSIONS, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_ONE_OPENS, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_ONE_OPENS, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_ONE_TIME_TO_OPEN, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_ONE_TIME_TO_OPEN, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_ONE_CLICKS, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_ONE_CLICKS, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_ONE_TIME_IN_AR, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_ONE_TIME_IN_AR, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_ONE_EMOTIONS, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_ONE_EMOTIONS, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_ONE_AGE, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_ONE_AGE, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_ONE_MALE, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_ONE_MALE, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_ONE_FEMALE, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_ONE_FEMALE, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_ONE_SMILE, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_ONE_SMILE, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_ONE_ANGER, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_ONE_ANGER, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_ONE_CONTEMPT, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_ONE_CONTEMPT, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_ONE_DISGUST, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_ONE_DISGUST, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_ONE_FEAR, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_ONE_FEAR, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_ONE_HAPPINESS, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_ONE_HAPPINESS, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_ONE_NEUTRAL, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_ONE_NEUTRAL, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_ONE_SADNESS, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_ONE_SADNESS, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_ONE_SURPRISE, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_ONE_SURPRISE, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_ONE_AD_INTERACTIONS_FRAME_ONE, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_ONE_AD_INTERACTIONS_FRAME_ONE, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_ONE_AD_INTERACTIONS_FRAME_TWO, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_ONE_AD_INTERACTIONS_FRAME_TWO, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_ONE_AD_INTERACTIONS_FRAME_THREE, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_ONE_AD_INTERACTIONS_FRAME_THREE, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_ONE, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_ONE, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_TWO, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_TWO, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_THREE, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_THREE, payload: Array<any>): ReduxAction;

export function makeAction(type: Actions.GET_UNIT_TWO_IMPRESSIONS, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_TWO_IMPRESSIONS, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_TWO_OPENS, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_TWO_OPENS, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_TWO_TIME_TO_OPEN, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_TWO_TIME_TO_OPEN, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_TWO_CLICKS, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_TWO_CLICKS, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_TWO_TIME_IN_AR, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_TWO_TIME_IN_AR, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_TWO_EMOTIONS, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_TWO_EMOTIONS, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_TWO_AGE, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_TWO_AGE, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_TWO_MALE, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_TWO_MALE, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_TWO_FEMALE, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_TWO_FEMALE, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_TWO_SMILE, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_TWO_SMILE, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_TWO_ANGER, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_TWO_ANGER, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_TWO_CONTEMPT, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_TWO_CONTEMPT, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_TWO_DISGUST, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_TWO_DISGUST, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_TWO_FEAR, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_TWO_FEAR, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_TWO_HAPPINESS, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_TWO_HAPPINESS, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_TWO_NEUTRAL, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_TWO_NEUTRAL, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_TWO_SADNESS, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_TWO_SADNESS, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_TWO_SURPRISE, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_TWO_SURPRISE, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_TWO_AD_INTERACTIONS_FRAME_ONE, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_TWO_AD_INTERACTIONS_FRAME_ONE, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_TWO_AD_INTERACTIONS_FRAME_TWO, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_TWO_AD_INTERACTIONS_FRAME_TWO, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_TWO_AD_INTERACTIONS_FRAME_THREE, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_TWO_AD_INTERACTIONS_FRAME_THREE, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_ONE, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_ONE, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_TWO, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_TWO, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_THREE, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_THREE, payload: Array<any>): ReduxAction;

export function makeAction(type: Actions.GET_UNIT_THREE_IMPRESSIONS, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_THREE_IMPRESSIONS, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_THREE_OPENS, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_THREE_OPENS, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_THREE_TIME_TO_OPEN, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_THREE_TIME_TO_OPEN, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_THREE_CLICKS, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_THREE_CLICKS, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_THREE_TIME_IN_AR, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_THREE_TIME_IN_AR, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_THREE_EMOTIONS, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_THREE_EMOTIONS, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_THREE_AGE, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_THREE_AGE, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_THREE_MALE, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_THREE_MALE, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_THREE_FEMALE, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_THREE_FEMALE, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_THREE_SMILE, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_THREE_SMILE, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_THREE_ANGER, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_THREE_ANGER, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_THREE_CONTEMPT, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_THREE_CONTEMPT, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_THREE_DISGUST, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_THREE_DISGUST, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_THREE_FEAR, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_THREE_FEAR, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_THREE_HAPPINESS, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_THREE_HAPPINESS, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_THREE_NEUTRAL, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_THREE_NEUTRAL, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_THREE_SADNESS, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_THREE_SADNESS, payload: Array<any>): ReduxAction;
// export function makeAction(type: Actions.GET_UNIT_THREE_SURPRISE, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_UNIT_THREE_SURPRISE, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_THREE_AD_INTERACTIONS_FRAME_ONE, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_THREE_AD_INTERACTIONS_FRAME_ONE, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_THREE_AD_INTERACTIONS_FRAME_TWO, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_THREE_AD_INTERACTIONS_FRAME_TWO, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_THREE_AD_INTERACTIONS_FRAME_THREE, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_THREE_AD_INTERACTIONS_FRAME_THREE, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_ONE, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_ONE, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_TWO, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_TWO, payload: Array<any>): ReduxAction;
export function makeAction(type: Actions.GET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_THREE, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_THREE, payload: Array<any>): ReduxAction;

//ads
export function makeAction(type: Actions.GET_ADS_ACTIVITY, payload: AdsDataParams): ReduxAction;
export function makeAction(type: Actions.SET_ADS_ACTIVITY, payload: AppState.AdsDashboardData["activity"]): ReduxAction;
// export function makeAction(type: Actions.GET_ADS_OVERVIEW, payload: AdsDataParams): ReduxAction;
// export function makeAction(type: Actions.SET_ADS_OVERVIEW, payload: AppState.AdsDashboardData["overview"]): ReduxAction;

export function makeAction(type: /* Actions.GET_BRAND_DETAILS | */ Actions.GET_EMBEDS, payload: { brand: string }): ReduxAction;
export function makeAction(type: Actions.GET_EMBED_DETAILS, payload: { embed: string }): ReduxAction;
// export function makeAction(type: Actions.GET_OVERVIEW_DATA, payload: ActionPayloads.ScopeFilter): ReduxAction;
export function makeAction(type: Actions.GET_PERIODIC_DATA | Actions.GET_OVERVIEW_DATA, payload: { timeRange: ActionPayloads.TimeRange, scopeFilter: ActionPayloads.ScopeFilter }): ReduxAction;
export function makeAction(type: Actions.GET_BRANDS): ReduxAction;
export function makeAction(type: Actions.SET_DASHBOARD_OVERVIEW_DATA, payload: Omit<AppState.DashboardData, "periodChartData">): ReduxAction;
export function makeAction(type: Actions.SET_DASHBOARD_PERIODIC_DATA, payload: AppState.DashboardData["periodChartData"]): ReduxAction;
export function makeAction(type: Actions.SET_EMBEDS_LIST, payload: AppState.Embed[]): ReduxAction;
export function makeAction(type: Actions.SET_BRANDS_LIST, payload: AppState.Brand[]): ReduxAction;
export function makeAction(type: Actions.SET_CURRENT_EMBED, payload: AppState.Embed): ReduxAction;
export function makeAction(type: Actions.SET_SESSION, payload: Session): ReduxAction;
export function makeAction(type: Actions.LOGIN, payload: { login: string, password: string }): ReduxAction;
export function makeAction(type: Actions.GET_APP_OVERVIEW, payload: WithScope<AppDataParams>): ReduxAction;
export function makeAction(type: Actions.GET_APP_ACTIVITY, payload: WithScope<AppActivityParams>): ReduxAction;
export function makeAction(type: Actions.SET_APP_OVERVIEW, payload: AppState.AppDashboardData["overview"]): ReduxAction;
export function makeAction(type: Actions.SET_APP_ACTIVITY, payload: AppState.AppDashboardData["activity"]): ReduxAction;
export function makeAction(type: Actions.SET_APP_BRANDS_LIST, payload: AppState.AppBrand[]): ReduxAction;
export function makeAction(type: Actions.SET_CURRENT_APP_BRAND, payload: AppState.AppBrand): ReduxAction;
export function makeAction(type: Actions.SET_CURRENT_CHANNEL, payload: AppState.Channel): ReduxAction;
export function makeAction(type: Actions.SET_CURRENT_EXPERIENCE, payload: AppState.Experience): ReduxAction;
export function makeAction(type: Actions.SET_EXP_IDS, payload: string[]): ReduxAction;
export function makeAction(type: Actions.SET_NOT_FOUND, payload: boolean): ReduxAction;
export function makeAction(type: Actions.UPDATE_SESSION | Actions.GET_EXPERIENCE_LIST | Actions.SET_NOT_FOUND): ReduxAction;
export function makeAction(
  type: Actions.SET_OVERVIEW_WAIT_STATE | Actions.SET_PERIODIC_DATA_WAIT_STATE,
  payload: boolean
): ReduxAction;
// definition
export function makeAction(type: Actions, payload?: Record<string, any> | boolean): ReduxAction {
  return { type, payload }
}
