import { put } from 'redux-saga/effects'
import { ReduxAction, Session, AppState } from '@declarations';
import { makeAction, Actions } from '../Store/actions';
import { getEmbeds } from './embedSagas';
import { updateOverviewData } from './analyticsSagas';
import { login, getJWT, getSession, getBrandInfo } from '../Utils/RemoteCalls';


export function* updateSessionSaga({ payload: user }: ReduxAction<Session.UserInfo | never>) {
    put(makeAction(Actions.SET_SESSION, { state: "Verifying" }))

    try {

        let userDetails: Session.UserInfo;
        if (user) userDetails = user;
        else userDetails = yield getSession();
        yield getJWT();//token is in memory, not stored in Redux
        if (!userDetails.admin) {
            const brand: AppState.Brand = yield getBrandInfo(userDetails.brandId);
            userDetails.brandName = brand.name;
        }
        const sessionInfo: Session = {
            user: userDetails as Session.UserInfo,
            state: "Auth"
        };
        yield put(makeAction(Actions.SET_SESSION, sessionInfo))

    } catch (e) {
        console.log("fetch", e)
        yield put(makeAction(Actions.SET_SESSION, { state: "Guest" }))
    }

}

export function* loginSaga({ payload }: ReduxAction<{ login: string, password: string }>) {
    if (!payload) return;
    put(makeAction(Actions.SET_SESSION, { state: "Verifying" }));
    try {
        const userDetails: Session.UserInfo = yield login(payload?.login, payload?.password);
        yield updateSessionSaga({ payload: userDetails, type: "" });

    } catch (e) {
        yield put(makeAction(Actions.SET_SESSION, { state: "Guest", error: e.message }))
    }

}