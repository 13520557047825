import { ActionPayloads } from "@declarations";
import { XapiKeys } from "../Values/xapiKeys";
import { VeracityDate, generateTimeSampling } from "./methods";

const PERIODIC_DATA_SAMPLE_SIZE = 30
const upperDwellCap = 3600; // (1 hour) in seconds cut records above this threshold
// const unknownDwellTime = 60; // (1 minute) in seconds use this for all sessions that didn't end
const lowerDwellCap = 1; // in seconds cut records above this threshold


const MATCH_VIEWS = {
    "statement.verb.id": XapiKeys.Verbs.view,
    "statement.object.definition.type": XapiKeys.Definitions.Button
};
const MATCH_OPENS = {
    "statement.verb.id": XapiKeys.Verbs.open,
    "statement.object.definition.type": {
        $in: [XapiKeys.Definitions["3DView"], XapiKeys.Definitions.ARView, XapiKeys.Definitions.Mobile3DView, XapiKeys.Definitions.TryOn]
    }
};
const MATCH_ADD_TO_CART = {
    "statement.verb.id": XapiKeys.Verbs.addToCart,
    "statement.object.definition.type": {
        $in: [XapiKeys.Definitions["3DView"], XapiKeys.Definitions.ARView, XapiKeys.Definitions.Mobile3DView, XapiKeys.Definitions.TryOn]
    }
};
const MATCH_DWELL_EVENTS = {
    "statement.verb.id": XapiKeys.Verbs.complete,
    "statement.object.definition.type": {
        $in: [XapiKeys.Definitions["3DView"], XapiKeys.Definitions.ARView, XapiKeys.Definitions.Mobile3DView, XapiKeys.Definitions.TryOn]
    },


};

// fields in extensions can't be matched in first $match stage. 
// It has to be projected to another key and then matched.
// This could be due to some implementation detail of verasity

const PROJECTED_EXT_FIELD = `EXT`;
const EXT_MATCH_DWELL_EVENTS = {
    [PROJECTED_EXT_FIELD + ".https://arecomm*`*nextechar*`*com/extension/durationSeconds"]: {
        $lte: upperDwellCap, "$gte": lowerDwellCap
    }
}

const AUTO_BUCKET_STAGES = [{// for bundling the by-period data into a predefined number of slices.
    // add this if getting data over a long period of time

    "$bucketAuto": {
        "groupBy": "$_id",
        "buckets": PERIODIC_DATA_SAMPLE_SIZE,
        "output": { "openCount": { "$sum": "$openCount" } }
    }
}, {
    "$addFields": {
        "_id": "$_id.min"
    }
},]

// Template queries

const queries = {
    overview_template: [
        {
            "$match": {
                $or: [
                    { ...MATCH_VIEWS },
                    { ...MATCH_OPENS },
                    { ...MATCH_ADD_TO_CART },
                    { ...MATCH_DWELL_EVENTS },
                ]
            }
        },
        {
            "$addFields": {
                [PROJECTED_EXT_FIELD]: "$statement.object.definition"
            }
        },
        {
            "$addFields": {
                [PROJECTED_EXT_FIELD]: `$${PROJECTED_EXT_FIELD}.extensions`
            }
        },
        {
            "$match": {
                $or: [
                    { ...MATCH_VIEWS },
                    { ...MATCH_OPENS },
                    { ...MATCH_ADD_TO_CART },
                    { ...MATCH_DWELL_EVENTS, ...EXT_MATCH_DWELL_EVENTS },
                ]
            }
        },
        {
            "$group": {
                "_id": {
                    verb: "$statement.verb.id",
                    viewDevice: "$statement.object.definition.extensions.https://arecomm*`*nextechar*`*com/extension/type",
                    defType: "$statement.object.definition.type"
                },

                "count": {
                    "$sum": 1
                },

                "avgDuration": { "$avg": "$statement.object.definition.extensions.https://arecomm*`*nextechar*`*com/extension/durationSeconds" },
                "totalDuration": { "$sum": "$statement.object.definition.extensions.https://arecomm*`*nextechar*`*com/extension/durationSeconds" },
            }
        },
    ],


    opens_by_day_template: [
        {
            "$match": { ...MATCH_OPENS },
        },
        {
            "$group": {
                "_id": {
                    "$dateToString": {
                        "format": "%Y-%m-%dT00:00:00.000Z",
                        "date": "$statement.timestamp"
                    }
                },
                "openCount": {
                    "$sum": 1
                }
            }
        },
        {
            "$addFields": {
                "isoTime": {
                    "$toDate": "$_id"
                }
            }
        },
        {
            "$sort": {
                "isoTime": 1
            }
        },
        // ...AUTO_BUCKET_STAGES
    ]
}


export const getGlobalOverviewQry = (timeRange: ActionPayloads.TimeRange) => {
    const copy = JSON.parse(JSON.stringify(queries.overview_template));
    const [{ $match }] = copy;  // these objects mutate actual query data
    if (timeRange.to && timeRange.from) {
        $match["statement.timestamp"] = { $lte: VeracityDate(timeRange.to), "$gte": VeracityDate(timeRange.from) };
    } else if (timeRange.to) {
        $match["statement.timestamp"] = { $lte: VeracityDate(timeRange.to) }
    } else if (timeRange.from) {
        $match["statement.timestamp"] = { $gte: VeracityDate(timeRange.from) }
    }
    console.log("copy$$", copy)
    return copy;
}

export const getGlobalOpensByDay = (timeRange: ActionPayloads.TimeRange = {}) => {



    const copy = JSON.parse(JSON.stringify(queries.opens_by_day_template));
    const [{ $match }, { $group }, { $addFields }, { $sort }] = copy;  // these objects mutate actual query data
    const {
        from: fromTime = (d => new Date(d.setDate(d.getDate() - 1)))(new Date), // yesterday,
        to: toTime = new Date() // now
    } = timeRange;
    $group._id.$dateToString.format = generateTimeSampling(fromTime, toTime);
    // $bucketAuto.buckets = timeDiffDays && timeDiffDays < PERIODIC_DATA_SAMPLE_SIZE ? timeDiffDays : PERIODIC_DATA_SAMPLE_SIZE
    $match["statement.timestamp"] = { $lte: VeracityDate(toTime), "$gte": VeracityDate(fromTime) };

    // console.log("copy", copy)

    return copy;
}

export const getUriOverview = (timeRange: ActionPayloads.TimeRange, pageUris: string[]) => {
    const query = getGlobalOverviewQry(timeRange);
    const [{ $match }, { $group }] = query;
    $match["statement.object.id"] = { $in: pageUris };
    // $match['statement.actor.mbox'] = `mailto:${brandId}@ntar.com`;
    // console.log("copy", copy)
    return query;
}

export const getUriOpensByDay = (timeRange: ActionPayloads.TimeRange, scope: ActionPayloads.QryScope, uris: string[]) => {
    const query = getGlobalOpensByDay(timeRange);
    const [{ $match }, { $group }] = query;
    $match["statement.object.id"] = { $in: uris };
    return query;
    // rest of query
}

export const getCartAddsByPeriod = (timeRange: ActionPayloads.TimeRange, scope: ActionPayloads.QryScope, uris: string[]) => {
    let query;
    if (scope === "global") query = getGlobalOpensByDay(timeRange);
    else query = getUriOpensByDay(timeRange, scope, uris);
    const [{ $match }] = query;
    Object.assign($match, MATCH_ADD_TO_CART);
    return query;
}