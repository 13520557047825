import React from "react"
import { Row, Col, Button } from "react-bootstrap"

import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment, { Moment } from "moment"

import "./index.css";

export type MomentRange = {
    startDate: Moment
    endDate: Moment
}
interface Props {
    startDate: moment.Moment
    startDateId: string,
    endDate: moment.Moment,
    endDateId: string,
    focusedInput: "START_DATE" | "END_DATE" | null,
    onFocusChange: Function
    defaultRange: number
    updateDate: Function
}

export const DatePicker: React.FC<Props> = (props) => {

    const [range, setRange] = React.useState(props.defaultRange)

    const [dates, setDates] = React.useState<MomentRange>({
        startDate: props.startDate,
        endDate: props.endDate
    })
    const [focusedInput, setFocusedInput] = React.useState(props.focusedInput)

    const changeRange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, range: number): void => {

        const start = moment().subtract(range, 'days')
        const now = moment()

        setDates({
            startDate: start,
            endDate: now
        })

        setRange(range)

        props.updateDate({
            startDate: start,
            endDate: now
        })

    }

    const specifyDates = (dateRange: MomentRange) => {

        if (dateRange.startDate && dateRange.startDate.isAfter(moment())) {
            dateRange.startDate = moment()
        }

        if (dateRange.endDate && dateRange.endDate.isAfter(moment())) {
            dateRange.endDate = moment()
        }

        if (dateRange.endDate !== null && dateRange.startDate !== null) {
            const range = moment.duration(dateRange.endDate.diff(dateRange.startDate));
            setRange(range.asDays())
        } else {
            setRange(0)
        }

        setDates(dateRange)
        props.updateDate(dateRange)

    }

    return (
        <Row className="date-container">
            <Col>

                <DateRangePicker
                    //required props
                    startDate={dates.startDate} // momentPropTypes.momentObj or null,
                    startDateId={props.startDateId} // PropTypes.string.isRequired,
                    endDate={dates.endDate} // momentPropTypes.momentObj or null,
                    endDateId={props.endDateId} // PropTypes.string.isRequired,
                    onDatesChange={specifyDates} // PropTypes.func.isRequired,
                    focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={(focusedInput: any) => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,

                    //optional props
                    anchorDirection="left"
                    block={false}
                    customArrowIcon={null}
                    customCloseIcon={null}
                    customInputIcon={null}
                    disabled={false}
                    displayFormat={function noRefCheck() { }}
                    enableOutsideDays={false}
                    endDatePlaceholderText="End Date"
                    horizontalMargin={0}
                    initialVisibleMonth={null}
                    isDayBlocked={function noRefCheck() { }}
                    isDayHighlighted={function noRefCheck() { }}
                    isOutsideRange={function noRefCheck() { }}
                    isRTL={false}
                    keepOpenOnDateSelect={false}
                    maxDate={moment()}
                    minDate={null}
                    minimumNights={0}
                    monthFormat="MMMM YYYY"
                    navNext={null}
                    navPosition="navPositionTop"
                    navPrev={null}
                    numberOfMonths={2}
                    onClose={function noRefCheck() { }}
                    onNextMonthClick={function noRefCheck() { }}
                    onPrevMonthClick={function noRefCheck() { }}
                    orientation="horizontal"
                    phrases={{
                        calendarLabel: 'Calendar',
                        chooseAvailableEndDate: function noRefCheck() { },
                        chooseAvailableStartDate: function noRefCheck() { },
                        clearDates: 'Clear Dates',
                        closeDatePicker: 'Close',
                        dateIsSelected: function noRefCheck() { },
                        dateIsSelectedAsEndDate: function noRefCheck() { },
                        dateIsSelectedAsStartDate: function noRefCheck() { },
                        dateIsUnavailable: function noRefCheck() { },
                        enterKey: 'Enter key',
                        escape: 'Escape key',
                        focusStartDate: 'Interact with the calendar and add the check-in date for your trip.',
                        hideKeyboardShortcutsPanel: 'Close the shortcuts panel.',
                        homeEnd: 'Home and end keys',
                        jumpToNextMonth: 'Move forward to switch to the next month.',
                        jumpToPrevMonth: 'Move backward to switch to the previous month.',
                        keyboardBackwardNavigationInstructions: 'Navigate backward to interact with the calendar and select a date. Press the question mark key to get the keyboard shortcuts for changing dates.',
                        keyboardForwardNavigationInstructions: 'Navigate forward to interact with the calendar and select a date. Press the question mark key to get the keyboard shortcuts for changing dates.',
                        keyboardShortcuts: 'Keyboard Shortcuts',
                        leftArrowRightArrow: 'Right and left arrow keys',
                        moveFocusByOneDay: 'Move backward (left) and forward (right) by one day.',
                        moveFocusByOneMonth: 'Switch months.',
                        moveFocusByOneWeek: 'Move backward (up) and forward (down) by one week.',
                        moveFocustoStartAndEndOfWeek: 'Go to the first or last day of a week.',
                        openThisPanel: 'Open this panel.',
                        pageUpPageDown: 'page up and page down keys',
                        questionMark: 'Question mark',
                        returnFocusToInput: 'Return to the date input field.',
                        roleDescription: 'datepicker',
                        selectFocusedDate: 'Select the date in focus.',
                        showKeyboardShortcutsPanel: 'Open the keyboard shortcuts panel.',
                        upArrowDownArrow: 'up and down arrow keys'
                    }}
                    regular={false}
                    renderCalendarDay={undefined}
                    renderDayContents={null}
                    renderMonthText={null}
                    reopenPickerOnClearDates={false}
                    required={false}
                    screenReaderInputMessage=""
                    showClearDates={false}
                    showDefaultInputIcon={false}
                    small={false}
                    startDatePlaceholderText="Start Date"
                    // stateDateWrapper={function noRefCheck(){}}
                    transitionDuration={300}
                    withFullScreenPortal={false}
                    withPortal={false}
                />
                <br />
                <div className="ranges">
                    <Button variant={range === 5 ? "outline-primary" : "outline-secondary"} className="range" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => changeRange(e, 5)}>5 Days</Button>
                    <Button variant={range === 10 ? "outline-primary" : "outline-secondary"} className="range" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => changeRange(e, 10)}>10 Days</Button>
                    <Button variant={range === 30 ? "outline-primary" : "outline-secondary"} className="range" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => changeRange(e, 30)}>30 Days</Button>
                </div>


            </Col>
        </Row>
    )

}