import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import { store } from './Store';
import { makeAction, Actions } from './Store/actions';

ReactDOM.render(<Provider store={store}>
  <App />
</Provider>, document.getElementById('root'));

store.dispatch(makeAction(Actions.UPDATE_SESSION))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
