
import { put } from 'redux-saga/effects'
import { ReduxAction, AppState } from '@declarations';
import { Actions, makeAction } from '../Store/actions';
import { store } from '../Store';
import { getEmbed, getEmbedsSummary } from '../Utils/RemoteCalls';


export function* getEmbeds({ payload }: ReduxAction<{ brand: string }>) {
    if (!payload) throw new Error("Get Embeds should be called with parent brand info")
    const data: AppState.Embed[] = yield getEmbedsSummary();
    const filteredSet = data.filter((embed: any) => {
        // console.log("match check",embed.brand.name,payload.brand)
        return embed.brand.name === payload?.brand
    }).sort((a, b) => {
        if (a.name === b.name) return 0;
        if (a.name > b.name) return 1;
        return -1;
    });
    yield put(makeAction(Actions.SET_EMBEDS_LIST, filteredSet));
}



export function* getEmbedDetails({ payload }: ReduxAction<{ embed: string }>) {
    if (!payload || !payload.embed) throw new Error("Get Embed details should be called with embed id")
    const list: AppState.Embed[] = yield getEmbedsSummary();
    // const list = store.getState().embedsListInView;
    if (!list.length) throw new Error("fetch embedlist first")
    const currentEmbed = list.find((embed: any) => {
        return embed.pageUrl === payload.embed
    })
    if (!currentEmbed) {
        // set a ui state
    }
    else {
        try {
            const details: AppState.EmbedDetails = yield getEmbed(currentEmbed.id)
            currentEmbed.cart = details.settings.cart;
        } catch(e){
            currentEmbed.cart="none";
            console.error("Failed to get embed details")
        }
        
        yield put(makeAction(Actions.SET_CURRENT_EMBED, currentEmbed));
    }
}
