import { put } from 'redux-saga/effects'
import { ReduxAction, AppState } from '@declarations';
import { makeAction, Actions } from '../Store/actions';
import { getEmbeds } from './embedSagas';
import { updateOverviewData } from './analyticsSagas';
import { getEmbedsSummary } from '../Utils/RemoteCalls';

const excludeList = new Set([
    "Example",
    "DevJustin",
    "devScott",
    "NexTech Sales",
    "NexTech AR Solutions Corp",
    "Developer Portal",
    "devContent",
    "devIan"
])

export function* getBrands() {

    const data: AppState.Embed[] = yield getEmbedsSummary();
    const reducedMap = data.reduce((hashmap: Record<string, any>, embed: any) => {

        if (excludeList.has(embed.brand.name)) return hashmap;

        const embedsOfBrand = hashmap[embed.brand.name] || [];
        embedsOfBrand.push(embed);
        hashmap[embed.brand.name] = embedsOfBrand;
        return hashmap;
    }, {})

    const brandInfo = Object.keys(reducedMap).map(name => ({
        name,
        pageUrls: reducedMap[name].map((embed: any) => embed.pageUrl)
    })).sort((a, b) => {
        if (a.name === b.name) return 0;
        if (a.name > b.name) return 1;
        return -1;
    });
    yield put(makeAction(Actions.SET_BRANDS_LIST, brandInfo));

}

export function* getBrandDetails({ payload }: ReduxAction<{ brand: string }>) {

}