import React, { useState, useEffect, Dispatch } from 'react'
import { PageProps, AppState, ReduxAction, ActionPayloads } from "@declarations";
import { makeAction, Actions } from "../../Store/actions";
import { connect } from "react-redux"

import { Row, Col, Tabs, Tab } from 'react-bootstrap'

import { CSVLink } from "react-csv";

import { InteractionsTable } from "../../Widgets/InteractionsTable"
import { EmotionsTable } from "../../Widgets/EmotionsTable"

import { DatePicker, MomentRange } from "../../Widgets/DatePicker"
import moment from "moment"

import { Constants } from "../../Values/constants";

import './index.css'

interface Props extends PageProps<typeof mapStateToProps, typeof mapDispatchToProps> {

}

export const AdsDashboardView: React.FC<Props> = (props) => {

    const [dateRange, setDateRange] = useState(5)
    const [dates, setDates] = useState<MomentRange>({
        startDate: moment().subtract(dateRange, 'days'),
        endDate: moment()
    })

    const updateDates = ({ startDate, endDate }: MomentRange) => {
        let start = startDate.startOf('day');
        let end = endDate.endOf('day')
        setDates({ startDate: start, endDate: end });
        let diffInDays = endDate.diff(startDate, 'days');
        setDateRange(diffInDays);
    }

    const [tabKey, setTabKey] = useState<string | null>("emotions")

    const {
        getUnitOneAdImpressions,
        getUnitOneAdOpens,
        getUnitOneTimeToOpen,
        getUnitOneAdClicks,
        getUnitOneTimeInAR,
        getUnitOneEmotions,
        // getUnitOneAge,
        // getUnitOneMale,
        // getUnitOneFemale,
        // getUnitOneSmile,
        // getUnitOneAnger,
        // getUnitOneContempt,
        // getUnitOneDisgust,
        // getUnitOneFear,
        // getUnitOneHappiness,
        // getUnitOneNeutral,
        // getUnitOneSadness,
        // getUnitOneSurprise,
        getUnitOneAdInteractionsFrameOne,
        getUnitOneAdInteractionsFrameTwo,
        getUnitOneAdInteractionsFrameThree,
        getUnitOneTryOnInteractionsFrameOne,
        getUnitOneTryOnInteractionsFrameTwo,
        getUnitOneTryOnInteractionsFrameThree,

        getUnitTwoAdImpressions,
        getUnitTwoAdOpens,
        getUnitTwoTimeToOpen,
        getUnitTwoAdClicks,
        getUnitTwoTimeInAR,
        getUnitTwoEmotions,
        // getUnitTwoAge,
        // getUnitTwoMale,
        // getUnitTwoFemale,
        // getUnitTwoSmile,
        // getUnitTwoAnger,
        // getUnitTwoContempt,
        // getUnitTwoDisgust,
        // getUnitTwoFear,
        // getUnitTwoHappiness,
        // getUnitTwoNeutral,
        // getUnitTwoSadness,
        // getUnitTwoSurprise,
        getUnitTwoAdInteractionsFrameOne,
        getUnitTwoAdInteractionsFrameTwo,
        getUnitTwoAdInteractionsFrameThree,
        getUnitTwoTryOnInteractionsFrameOne,
        getUnitTwoTryOnInteractionsFrameTwo,
        getUnitTwoTryOnInteractionsFrameThree,

        getUnitThreeAdImpressions,
        getUnitThreeAdOpens,
        getUnitThreeTimeToOpen,
        getUnitThreeAdClicks,
        getUnitThreeTimeInAR,
        getUnitThreeEmotions,
        // getUnitThreeAge,
        // getUnitThreeMale,
        // getUnitThreeFemale,
        // getUnitThreeSmile,
        // getUnitThreeAnger,
        // getUnitThreeContempt,
        // getUnitThreeDisgust,
        // getUnitThreeFear,
        // getUnitThreeHappiness,
        // getUnitThreeNeutral,
        // getUnitThreeSadness,
        // getUnitThreeSurprise,
        getUnitThreeAdInteractionsFrameOne,
        getUnitThreeAdInteractionsFrameTwo,
        getUnitThreeAdInteractionsFrameThree,
        getUnitThreeTryOnInteractionsFrameOne,
        getUnitThreeTryOnInteractionsFrameTwo,
        getUnitThreeTryOnInteractionsFrameThree,

    } = props;

    useEffect(() => {

        if (tabKey === "interactions") {

            getUnitOneAdImpressions({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitOneAdOpens({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitOneTimeToOpen({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitOneAdClicks({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitOneTimeInAR({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitOneAdInteractionsFrameOne({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitOneAdInteractionsFrameTwo({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitOneAdInteractionsFrameThree({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitOneTryOnInteractionsFrameOne({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitOneTryOnInteractionsFrameTwo({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitOneTryOnInteractionsFrameThree({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });

            getUnitTwoAdImpressions({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitTwoAdOpens({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitTwoTimeToOpen({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitTwoAdClicks({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitTwoTimeInAR({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitTwoAdInteractionsFrameOne({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitTwoAdInteractionsFrameTwo({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitTwoAdInteractionsFrameThree({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitTwoTryOnInteractionsFrameOne({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitTwoTryOnInteractionsFrameTwo({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitTwoTryOnInteractionsFrameThree({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });

            getUnitThreeAdImpressions({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitThreeAdOpens({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitThreeTimeToOpen({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitThreeAdClicks({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitThreeTimeInAR({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitThreeAdInteractionsFrameOne({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitThreeAdInteractionsFrameTwo({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitThreeAdInteractionsFrameThree({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitThreeTryOnInteractionsFrameOne({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitThreeTryOnInteractionsFrameTwo({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitThreeTryOnInteractionsFrameThree({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });

        } else if (tabKey === "emotions") {

            getUnitOneEmotions({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitTwoEmotions({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            getUnitThreeEmotions({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });

            // getUnitOneAge({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitOneMale({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitOneFemale({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitOneSmile({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitOneAnger({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitOneContempt({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitOneDisgust({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitOneFear({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitOneHappiness({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitOneNeutral({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitOneSadness({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitOneSurprise({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });

            // getUnitTwoAge({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitTwoMale({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitTwoFemale({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitTwoSmile({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitTwoAnger({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitTwoContempt({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitTwoDisgust({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitTwoFear({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitTwoHappiness({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitTwoNeutral({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitTwoSadness({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitTwoSurprise({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });

            // getUnitThreeAge({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitThreeMale({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitThreeFemale({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitThreeSmile({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitThreeAnger({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitThreeContempt({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitThreeDisgust({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitThreeFear({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitThreeHappiness({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitThreeNeutral({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitThreeSadness({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });
            // getUnitThreeSurprise({ from: dates.startDate.toDate(), to: dates.endDate.toDate() });

        }

    }, [
        getUnitOneAdImpressions,
        getUnitOneAdOpens,
        getUnitOneTimeToOpen,
        getUnitOneAdClicks,
        getUnitOneTimeInAR,
        getUnitOneEmotions,
        // getUnitOneAge,
        // getUnitOneMale,
        // getUnitOneFemale,
        // getUnitOneSmile,
        // getUnitOneAnger,
        // getUnitOneContempt,
        // getUnitOneDisgust,
        // getUnitOneFear,
        // getUnitOneHappiness,
        // getUnitOneNeutral,
        // getUnitOneSadness,
        // getUnitOneSurprise,
        getUnitOneAdInteractionsFrameOne,
        getUnitOneAdInteractionsFrameTwo,
        getUnitOneAdInteractionsFrameThree,
        getUnitOneTryOnInteractionsFrameOne,
        getUnitOneTryOnInteractionsFrameTwo,
        getUnitOneTryOnInteractionsFrameThree,

        getUnitTwoAdImpressions,
        getUnitTwoAdOpens,
        getUnitTwoTimeToOpen,
        getUnitTwoAdClicks,
        getUnitTwoTimeInAR,
        getUnitTwoEmotions,
        // getUnitTwoAge,
        // getUnitTwoMale,
        // getUnitTwoFemale,
        // getUnitTwoSmile,
        // getUnitTwoAnger,
        // getUnitTwoContempt,
        // getUnitTwoDisgust,
        // getUnitTwoFear,
        // getUnitTwoHappiness,
        // getUnitTwoNeutral,
        // getUnitTwoSadness,
        // getUnitTwoSurprise,
        getUnitTwoAdInteractionsFrameOne,
        getUnitTwoAdInteractionsFrameTwo,
        getUnitTwoAdInteractionsFrameThree,
        getUnitTwoTryOnInteractionsFrameOne,
        getUnitTwoTryOnInteractionsFrameTwo,
        getUnitTwoTryOnInteractionsFrameThree,

        getUnitThreeAdImpressions,
        getUnitThreeAdOpens,
        getUnitThreeTimeToOpen,
        getUnitThreeAdClicks,
        getUnitThreeTimeInAR,
        getUnitThreeEmotions,
        // getUnitThreeAge,
        // getUnitThreeMale,
        // getUnitThreeFemale,
        // getUnitThreeSmile,
        // getUnitThreeAnger,
        // getUnitThreeContempt,
        // getUnitThreeDisgust,
        // getUnitThreeFear,
        // getUnitThreeHappiness,
        // getUnitThreeNeutral,
        // getUnitThreeSadness,
        // getUnitThreeSurprise,
        getUnitThreeAdInteractionsFrameOne,
        getUnitThreeAdInteractionsFrameTwo,
        getUnitThreeAdInteractionsFrameThree,
        getUnitThreeTryOnInteractionsFrameOne,
        getUnitThreeTryOnInteractionsFrameTwo,
        getUnitThreeTryOnInteractionsFrameThree,

        dates,
        tabKey
    ])

    const { unitOneActivity, unitTwoActivity, unitThreeActivity, unitOneEmotions, unitTwoEmotions, unitThreeEmotions } = props;

    const csvInteractionsHeaders = [
        { label: "Date", key: "Date" },
        { label: "Impressions", key: "Impressions" },
        { label: "AR Unit Opens", key: "ARUnitOpens" },
        { label: "Time to Open", key: "TimeToOpen" },
        { label: "Clicks", key: "Clicks" },
        { label: "Time in AR", key: "TimeInAR" },
        { label: "Ad Interactions Frame 1 (Joseph Marc 4130)", key: "AdInteractionsFrame1" },
        { label: "Ad Interactions Frame 2 (Kam Dhillon)", key: "AdInteractionsFrame2" },
        { label: "Ad Interactions Frame 3 (Ray Ban)", key: "AdInteractionsFrame3" },
        { label: "Try-On Interactions Frame 1 (Joseph Marc 4130)", key: "TryOnInteractionsFrame1" },
        { label: "Try-On Interactions Frame 2 (Kam Dhillon)", key: "TryOnInteractionsFrame2" },
        { label: "Try-On Interactions Frame 3 (Ray Ban)", key: "TryOnInteractionsFrame3" },
    ];

    const csvEmotionsHeaders = [
        { label: "Date", key: "Date" },
        { label: "Age", key: "Age" },
        { label: "Gender", key: "Gender" },
        { label: "Smile", key: "Smile" },
        { label: "Anger", key: "Anger" },
        { label: "Contempt", key: "Contempt" },
        { label: "Disgust", key: "Disgust" },
        { label: "Fear", key: "Fear" },
        { label: "Happiness", key: "Happiness" },
        { label: "Neutral", key: "Neutral" },
        { label: "Sadness", key: "Sadness" },
        { label: "Surprise", key: "Surprise" }
    ];


    const [dataToDownload, setDataToDownload] = useState([]);
    const [csvFileName, setCSVFileName] = useState("data.csv");

    const prepDownloadCSV = async (unit: number) => {

        let type: string = ""

        if (tabKey === "interactions" || tabKey === "emotions") {
            type = tabKey;
        } else {
            console.log("invalid tab option! no data to download")
            return;
        }

        let activityData: AppState.TableRow[] = [];

        let emotionsData: AppState.EmotionsTableRow[] = [];

        if (unit === 1) {
            if (tabKey === "interactions") {
                activityData = unitOneActivity;
            } else if (tabKey === "emotions") {
                emotionsData = unitOneEmotions;
            }
        }
        else if (unit === 2) {
            if (tabKey === "interactions") {
                activityData = unitTwoActivity;
            } else if (tabKey === "emotions") {
                emotionsData = unitTwoEmotions;
            }
        }
        else if (unit === 3) {
            if (tabKey === "interactions") {
                activityData = unitThreeActivity;
            } else if (tabKey === "emotions") {
                emotionsData = unitThreeEmotions;
            }
        }
        else {
            console.log("invalid unit option! no data to download")
            return;
        }

        await setCSVFileName(`clearly-ad-unit-${unit}-${type}-data-${dateRange}-days.csv`);

        let dataToDownload: any = [];

        if (tabKey === "interactions") {
            activityData.forEach((row: AppState.TableRow) => {
                let cleanedRow = {};

                cleanedRow = {
                    Date: row.Date ? row.Date.toLocaleDateString() : new Date(),
                    Impressions: row.Impressions ? row.Impressions : 0,
                    ARUnitOpens: row.ARUnitOpens ? row.ARUnitOpens : 0,
                    TimeToOpen: row.TimeToOpen ? row.TimeToOpen.toFixed(2) : 0,
                    Clicks: row.Clicks ? row.Clicks : 0,
                    TimeInAR: row.TimeInAR ? row.TimeInAR.toFixed(2) : 0,
                    AdInteractionsFrame1: row.AdInteractionsFrame1 ? row.AdInteractionsFrame1 : 0,
                    AdInteractionsFrame2: row.AdInteractionsFrame2 ? row.AdInteractionsFrame2 : 0,
                    AdInteractionsFrame3: row.AdInteractionsFrame3 ? row.AdInteractionsFrame3 : 0,
                    TryOnInteractionsFrame1: row.TryOnInteractionsFrame1 ? row.TryOnInteractionsFrame1 : 0,
                    TryOnInteractionsFrame2: row.TryOnInteractionsFrame2 ? row.TryOnInteractionsFrame2 : 0,
                    TryOnInteractionsFrame3: row.TryOnInteractionsFrame3 ? row.TryOnInteractionsFrame3 : 0
                }


                dataToDownload.push(cleanedRow);
            })
        }
        else if (tabKey === "emotions") {
            emotionsData.forEach((row: AppState.EmotionsTableRow) => {
                let cleanedRow = {};

                cleanedRow = {
                    Date: row.Date ? row.Date.toLocaleDateString() : new Date(),
                    Age: row.Age ? row.Age.toFixed(0) : 0,
                    Gender: row.Gender ? row.Gender : "N/A",
                    Smile: row.Smile ? row.Smile.toFixed(4) : 0,
                    Anger: row.Anger ? row.Anger.toFixed(4) : 0,
                    Contempt: row.Contempt ? row.Contempt.toFixed(4) : 0,
                    Disgust: row.Disgust ? row.Disgust.toFixed(4) : 0,
                    Fear: row.Fear ? row.Fear.toFixed(4) : 0,
                    Happiness: row.Happiness ? row.Happiness.toFixed(4) : 0,
                    Neutral: row.Neutral ? row.Neutral.toFixed(4) : 0,
                    Sadness: row.Sadness ? row.Sadness.toFixed(4) : 0,
                    Surprise: row.Surprise ? row.Surprise.toFixed(4) : 0,
                }
                dataToDownload.push(cleanedRow);
            })
        }

        setDataToDownload(dataToDownload);

    }

    return <Row className="dashboard">
        <Col>
            <h1 className="page-title">Native-touch – Try-On Carousel – Statistics</h1>
            <br />

            <DatePicker
                startDate={dates.startDate}
                startDateId="START_DATE"
                endDate={dates.endDate}
                endDateId="END_DATE"
                focusedInput={null}
                onFocusChange={() => { }}
                defaultRange={dateRange}
                updateDate={updateDates}
            />

            <Tabs
                id="controlled-tab-example"
                activeKey={tabKey}
                onSelect={(k) => setTabKey(k)}
                className="nav-fill nav-justified"
            >
                <Tab eventKey="interactions" title="Interactions" className="nav-item">
                    <div>
                        <br />
                        <h2 className="table-title">Clearly Ad Unit 1</h2>
                        <CSVLink
                            className="csvLink"
                            data={dataToDownload}
                            headers={csvInteractionsHeaders}
                            filename={csvFileName}
                            asyncOnClick={true}
                            onClick={async (event, done) => {
                                await prepDownloadCSV(1);
                                done();
                            }}
                        >
                            Download Interactions CSV</CSVLink>
                        <InteractionsTable activity={unitOneActivity} />

                        <br />
                        <h2 className="table-title">Clearly Ad Unit 2</h2>
                        <CSVLink
                            className="csvLink"
                            data={dataToDownload}
                            headers={csvInteractionsHeaders}
                            filename={csvFileName}
                            asyncOnClick={true}
                            onClick={async (event, done) => {
                                await prepDownloadCSV(2);
                                done();
                            }}
                        >
                            Download Interactions CSV</CSVLink>
                        <InteractionsTable activity={unitTwoActivity} />

                        <br />
                        <h2 className="table-title">Clearly Ad Unit 3</h2>
                        <CSVLink
                            className="csvLink"
                            data={dataToDownload}
                            headers={csvInteractionsHeaders}
                            filename={csvFileName}
                            asyncOnClick={true}
                            onClick={async (event, done) => {
                                await prepDownloadCSV(3);
                                done();
                            }}
                        >
                            Download Interactions CSV</CSVLink>
                        <InteractionsTable activity={unitThreeActivity} />
                    </div>
                </Tab>


                <Tab eventKey="emotions" title="Emotions" className="nav-item">
                    <div>
                        <br />
                        <h2 className="table-title">Clearly Ad Unit 1</h2>
                        <CSVLink
                            className="csvLink"
                            data={dataToDownload}
                            headers={csvEmotionsHeaders}
                            filename={csvFileName}
                            asyncOnClick={true}
                            onClick={async (event, done) => {
                                await prepDownloadCSV(1);
                                done();
                            }}
                        >
                            Download Emotions CSV</CSVLink>
                        <EmotionsTable activity={unitOneEmotions} />
                        <br />
                        <h2 className="table-title">Clearly Ad Unit 2</h2>
                        <CSVLink
                            className="csvLink"
                            data={dataToDownload}
                            headers={csvEmotionsHeaders}
                            filename={csvFileName}
                            asyncOnClick={true}
                            onClick={async (event, done) => {
                                await prepDownloadCSV(2);
                                done();
                            }}
                        >
                            Download Emotions CSV</CSVLink>
                        <EmotionsTable activity={unitTwoEmotions} />
                        <br />
                        <h2 className="table-title">Clearly Ad Unit 3</h2>
                        <CSVLink
                            className="csvLink"
                            data={dataToDownload}
                            headers={csvEmotionsHeaders}
                            filename={csvFileName}
                            asyncOnClick={true}
                            onClick={async (event, done) => {
                                await prepDownloadCSV(3);
                                done();
                            }}
                        >
                            Download Emotions CSV</CSVLink>
                        <EmotionsTable activity={unitThreeEmotions} />
                    </div>
                </Tab>


            </Tabs>
        </Col>
    </Row>

}

const mapStateToProps = (state: AppState) => {
    const { unitOneActivity, unitTwoActivity, unitThreeActivity, unitOneEmotions, unitTwoEmotions, unitThreeEmotions } = state.adsDashboardData;
    const { waiting } = state;

    return {
        unitOneActivity, unitTwoActivity, unitThreeActivity, unitOneEmotions, unitTwoEmotions, unitThreeEmotions, waiting
    };
}

const mapDispatchToProps = (dispatch: Dispatch<ReduxAction>) => {
    return {

        // unit one
        getUnitOneAdImpressions: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_IMPRESSIONS,
                {
                    timeRange: timeRange,
                    verb: "Impressions",
                    ids: [Constants.unitOneId]
                })
        ),

        getUnitOneAdOpens: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_OPENS,
                {
                    timeRange: timeRange,
                    verb: "ARUnitOpens",
                    ids: [Constants.unitOneId]
                })
        ),

        getUnitOneTimeToOpen: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_TIME_TO_OPEN,
                {
                    timeRange: timeRange,
                    breakdownBy: "secondsFromStart",
                    verb: "ARUnitOpens",
                    ids: [Constants.unitOneId]
                })
        ),

        getUnitOneAdClicks: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_CLICKS,
                {
                    timeRange: timeRange,
                    verb: "Clicks",
                    ids: [Constants.unitOneTryOnId]
                })
        ),

        getUnitOneTimeInAR: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_TIME_IN_AR,
                {
                    timeRange: timeRange,
                    breakdownBy: "durationSeconds",
                    verb: "Clicks",
                    ids: [Constants.unitOneTryOnId]
                })
        ),
        getUnitOneEmotions: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_EMOTIONS,
                {
                    timeRange: timeRange,
                    verb: "Emoted",
                    ids: [Constants.unitOneTryOnId]
                })
        ),
        /*
        getUnitOneAge: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_AGE,
                {
                    timeRange: timeRange,
                    breakdownBy: "age",
                    verb: "Emoted",
                    ids: [Constants.unitOneTryOnId]
                })
        ),

        getUnitOneMale: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_MALE,
                {
                    timeRange: timeRange,
                    breakdownBy: "male",
                    verb: "Emoted",
                    ids: [Constants.unitOneTryOnId]
                })
        ),

        getUnitOneFemale: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_FEMALE,
                {
                    timeRange: timeRange,
                    breakdownBy: "female",
                    verb: "Emoted",
                    ids: [Constants.unitOneTryOnId]
                })
        ),

        getUnitOneSmile: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_SMILE,
                {
                    timeRange: timeRange,
                    breakdownBy: "smile",
                    verb: "Emoted",
                    ids: [Constants.unitOneTryOnId]
                })
        ),

        getUnitOneAnger: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_ANGER,
                {
                    timeRange: timeRange,
                    breakdownBy: "anger",
                    verb: "Emoted",
                    ids: [Constants.unitOneTryOnId]
                })
        ),

        getUnitOneContempt: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_CONTEMPT,
                {
                    timeRange: timeRange,
                    breakdownBy: "contempt",
                    verb: "Emoted",
                    ids: [Constants.unitOneTryOnId]
                })
        ),

        getUnitOneDisgust: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_DISGUST,
                {
                    timeRange: timeRange,
                    breakdownBy: "disgust",
                    verb: "Emoted",
                    ids: [Constants.unitOneTryOnId]
                })
        ),


        getUnitOneFear: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_FEAR,
                {
                    timeRange: timeRange,
                    breakdownBy: "fear",
                    verb: "Emoted",
                    ids: [Constants.unitOneTryOnId]
                })
        ),

        getUnitOneHappiness: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_HAPPINESS,
                {
                    timeRange: timeRange,
                    breakdownBy: "happiness",
                    verb: "Emoted",
                    ids: [Constants.unitOneTryOnId]
                })
        ),

        getUnitOneNeutral: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_NEUTRAL,
                {
                    timeRange: timeRange,
                    breakdownBy: "neutral",
                    verb: "Emoted",
                    ids: [Constants.unitOneTryOnId]
                })
        ),

        getUnitOneSadness: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_SADNESS,
                {
                    timeRange: timeRange,
                    breakdownBy: "sadness",
                    verb: "Emoted",
                    ids: [Constants.unitOneTryOnId]
                })
        ),

        getUnitOneSurprise: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_SURPRISE,
                {
                    timeRange: timeRange,
                    breakdownBy: "smile",
                    verb: "Emoted",
                    ids: [Constants.unitOneTryOnId]
                })
        ),
        */

        getUnitOneAdInteractionsFrameOne: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_AD_INTERACTIONS_FRAME_ONE,
                {
                    timeRange: timeRange,
                    verb: "Interact",
                    ids: [Constants.unitOneAdFrameOneId]
                })
        ),

        getUnitOneAdInteractionsFrameTwo: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_AD_INTERACTIONS_FRAME_TWO,
                {
                    timeRange: timeRange,
                    verb: "Interact",
                    ids: [Constants.unitOneAdFrameTwoId]
                })
        ),

        getUnitOneAdInteractionsFrameThree: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_AD_INTERACTIONS_FRAME_THREE,
                {
                    timeRange: timeRange,
                    verb: "Interact",
                    ids: [Constants.unitOneAdFrameThreeId]
                })
        ),


        getUnitOneTryOnInteractionsFrameOne: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_ONE,
                {
                    timeRange: timeRange,
                    breakdownBy: "josephMarc4130",
                    verb: "Interacted",
                    ids: [Constants.unitOneTryOnId]
                })
        ),


        getUnitOneTryOnInteractionsFrameTwo: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_TWO,
                {
                    timeRange: timeRange,
                    breakdownBy: "kamDhillon",
                    verb: "Interacted",
                    ids: [Constants.unitOneTryOnId]
                })
        ),


        getUnitOneTryOnInteractionsFrameThree: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_THREE,
                {
                    timeRange: timeRange,
                    breakdownBy: "rayBan",
                    verb: "Interacted",
                    ids: [Constants.unitOneTryOnId]
                })
        ),

        // unit two
        getUnitTwoAdImpressions: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_IMPRESSIONS,
                {
                    timeRange: timeRange,
                    verb: "Impressions",
                    ids: [Constants.unitTwoId]
                })
        ),

        getUnitTwoAdOpens: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_OPENS,
                {
                    timeRange: timeRange,
                    verb: "ARUnitOpens",
                    ids: [Constants.unitTwoId]
                })
        ),

        getUnitTwoTimeToOpen: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_TIME_TO_OPEN,
                {
                    timeRange: timeRange,
                    breakdownBy: "secondsFromStart",
                    verb: "ARUnitOpens",
                    ids: [Constants.unitTwoId]
                })
        ),

        getUnitTwoAdClicks: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_CLICKS,
                {
                    timeRange: timeRange,
                    verb: "Clicks",
                    ids: [Constants.unitTwoTryOnId]
                })
        ),

        getUnitTwoTimeInAR: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_TIME_IN_AR,
                {
                    timeRange: timeRange,
                    breakdownBy: "durationSeconds",
                    verb: "Clicks",
                    ids: [Constants.unitTwoTryOnId]
                })
        ),
        getUnitTwoEmotions: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_EMOTIONS,
                {
                    timeRange: timeRange,
                    breakdownBy: "age",
                    verb: "Emoted",
                    ids: [Constants.unitTwoTryOnId]
                })
        ),
        /*
        getUnitTwoAge: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_AGE,
                {
                    timeRange: timeRange,
                    breakdownBy: "age",
                    verb: "Emoted",
                    ids: [Constants.unitTwoTryOnId]
                })
        ),

        getUnitTwoMale: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_MALE,
                {
                    timeRange: timeRange,
                    breakdownBy: "male",
                    verb: "Emoted",
                    ids: [Constants.unitTwoTryOnId]
                })
        ),

        getUnitTwoFemale: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_FEMALE,
                {
                    timeRange: timeRange,
                    breakdownBy: "female",
                    verb: "Emoted",
                    ids: [Constants.unitTwoTryOnId]
                })
        ),

        getUnitTwoSmile: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_SMILE,
                {
                    timeRange: timeRange,
                    breakdownBy: "smile",
                    verb: "Emoted",
                    ids: [Constants.unitTwoTryOnId]
                })
        ),

        getUnitTwoAnger: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_ANGER,
                {
                    timeRange: timeRange,
                    breakdownBy: "anger",
                    verb: "Emoted",
                    ids: [Constants.unitTwoTryOnId]
                })
        ),

        getUnitTwoContempt: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_CONTEMPT,
                {
                    timeRange: timeRange,
                    breakdownBy: "contempt",
                    verb: "Emoted",
                    ids: [Constants.unitTwoTryOnId]
                })
        ),

        getUnitTwoDisgust: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_DISGUST,
                {
                    timeRange: timeRange,
                    breakdownBy: "disgust",
                    verb: "Emoted",
                    ids: [Constants.unitTwoTryOnId]
                })
        ),


        getUnitTwoFear: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_FEAR,
                {
                    timeRange: timeRange,
                    breakdownBy: "fear",
                    verb: "Emoted",
                    ids: [Constants.unitTwoTryOnId]
                })
        ),

        getUnitTwoHappiness: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_HAPPINESS,
                {
                    timeRange: timeRange,
                    breakdownBy: "happiness",
                    verb: "Emoted",
                    ids: [Constants.unitTwoTryOnId]
                })
        ),

        getUnitTwoNeutral: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_NEUTRAL,
                {
                    timeRange: timeRange,
                    breakdownBy: "neutral",
                    verb: "Emoted",
                    ids: [Constants.unitTwoTryOnId]
                })
        ),

        getUnitTwoSadness: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_SADNESS,
                {
                    timeRange: timeRange,
                    breakdownBy: "sadness",
                    verb: "Emoted",
                    ids: [Constants.unitTwoTryOnId]
                })
        ),

        getUnitTwoSurprise: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_SURPRISE,
                {
                    timeRange: timeRange,
                    breakdownBy: "smile",
                    verb: "Emoted",
                    ids: [Constants.unitTwoTryOnId]
                })
        ),
        */
        getUnitTwoAdInteractionsFrameOne: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_AD_INTERACTIONS_FRAME_ONE,
                {
                    timeRange: timeRange,
                    verb: "Interact",
                    ids: [Constants.unitTwoAdFrameOneId]
                })
        ),

        getUnitTwoAdInteractionsFrameTwo: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_AD_INTERACTIONS_FRAME_TWO,
                {
                    timeRange: timeRange,
                    verb: "Interact",
                    ids: [Constants.unitTwoAdFrameTwoId]
                })
        ),

        getUnitTwoAdInteractionsFrameThree: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_AD_INTERACTIONS_FRAME_THREE,
                {
                    timeRange: timeRange,
                    verb: "Interact",
                    ids: [Constants.unitTwoAdFrameThreeId]
                })
        ),

        getUnitTwoTryOnInteractionsFrameOne: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_ONE,
                {
                    timeRange: timeRange,
                    breakdownBy: "josephMarc4130",
                    verb: "Interacted",
                    ids: [Constants.unitTwoTryOnId]
                })
        ),


        getUnitTwoTryOnInteractionsFrameTwo: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_TWO,
                {
                    timeRange: timeRange,
                    breakdownBy: "kamDhillon",
                    verb: "Interacted",
                    ids: [Constants.unitTwoTryOnId]
                })
        ),


        getUnitTwoTryOnInteractionsFrameThree: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_THREE,
                {
                    timeRange: timeRange,
                    breakdownBy: "rayBan",
                    verb: "Interacted",
                    ids: [Constants.unitTwoTryOnId]
                })
        ),


        // unit three
        getUnitThreeAdImpressions: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_IMPRESSIONS,
                {
                    timeRange: timeRange,
                    verb: "Impressions",
                    ids: [Constants.unitThreeId]
                })
        ),

        getUnitThreeAdOpens: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_OPENS,
                {
                    timeRange: timeRange,
                    verb: "ARUnitOpens",
                    ids: [Constants.unitThreeId]
                })
        ),

        getUnitThreeTimeToOpen: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_TIME_TO_OPEN,
                {
                    timeRange: timeRange,
                    breakdownBy: "secondsFromStart",
                    verb: "ARUnitOpens",
                    ids: [Constants.unitThreeId]
                })
        ),

        getUnitThreeAdClicks: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_CLICKS,
                {
                    timeRange: timeRange,
                    verb: "Clicks",
                    ids: [Constants.unitThreeTryOnId]
                })
        ),

        getUnitThreeTimeInAR: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_TIME_IN_AR,
                {
                    timeRange: timeRange,
                    breakdownBy: "durationSeconds",
                    verb: "Clicks",
                    ids: [Constants.unitThreeTryOnId]
                })
        ),
        getUnitThreeEmotions: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_EMOTIONS,
                {
                    timeRange: timeRange,
                    verb: "Emoted",
                    ids: [Constants.unitThreeTryOnId]
                })
        ),
        /*
        getUnitThreeAge: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_AGE,
                {
                    timeRange: timeRange,
                    breakdownBy: "age",
                    verb: "Emoted",
                    ids: [Constants.unitThreeTryOnId]
                })
        ),

        getUnitThreeMale: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_MALE,
                {
                    timeRange: timeRange,
                    breakdownBy: "male",
                    verb: "Emoted",
                    ids: [Constants.unitThreeTryOnId]
                })
        ),

        getUnitThreeFemale: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_FEMALE,
                {
                    timeRange: timeRange,
                    breakdownBy: "female",
                    verb: "Emoted",
                    ids: [Constants.unitThreeTryOnId]
                })
        ),

        getUnitThreeSmile: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_SMILE,
                {
                    timeRange: timeRange,
                    breakdownBy: "smile",
                    verb: "Emoted",
                    ids: [Constants.unitThreeTryOnId]
                })
        ),

        getUnitThreeAnger: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_ANGER,
                {
                    timeRange: timeRange,
                    breakdownBy: "anger",
                    verb: "Emoted",
                    ids: [Constants.unitThreeTryOnId]
                })
        ),

        getUnitThreeContempt: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_CONTEMPT,
                {
                    timeRange: timeRange,
                    breakdownBy: "contempt",
                    verb: "Emoted",
                    ids: [Constants.unitThreeTryOnId]
                })
        ),

        getUnitThreeDisgust: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_DISGUST,
                {
                    timeRange: timeRange,
                    breakdownBy: "disgust",
                    verb: "Emoted",
                    ids: [Constants.unitThreeTryOnId]
                })
        ),


        getUnitThreeFear: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_FEAR,
                {
                    timeRange: timeRange,
                    breakdownBy: "fear",
                    verb: "Emoted",
                    ids: [Constants.unitThreeTryOnId]
                })
        ),

        getUnitThreeHappiness: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_HAPPINESS,
                {
                    timeRange: timeRange,
                    breakdownBy: "happiness",
                    verb: "Emoted",
                    ids: [Constants.unitThreeTryOnId]
                })
        ),

        getUnitThreeNeutral: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_NEUTRAL,
                {
                    timeRange: timeRange,
                    breakdownBy: "neutral",
                    verb: "Emoted",
                    ids: [Constants.unitThreeTryOnId]
                })
        ),

        getUnitThreeSadness: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_SADNESS,
                {
                    timeRange: timeRange,
                    breakdownBy: "sadness",
                    verb: "Emoted",
                    ids: [Constants.unitThreeTryOnId]
                })
        ),

        getUnitThreeSurprise: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_SURPRISE,
                {
                    timeRange: timeRange,
                    breakdownBy: "smile",
                    verb: "Emoted",
                    ids: [Constants.unitThreeTryOnId]
                })
        ),
        */
        getUnitThreeAdInteractionsFrameOne: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_AD_INTERACTIONS_FRAME_ONE,
                {
                    timeRange: timeRange,
                    verb: "Interact",
                    ids: [Constants.unitThreeAdFrameOneId]
                })
        ),

        getUnitThreeAdInteractionsFrameTwo: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_AD_INTERACTIONS_FRAME_TWO,
                {
                    timeRange: timeRange,
                    verb: "Interact",
                    ids: [Constants.unitThreeAdFrameTwoId]
                })
        ),

        getUnitThreeAdInteractionsFrameThree: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_AD_INTERACTIONS_FRAME_THREE,
                {
                    timeRange: timeRange,
                    verb: "Interact",
                    ids: [Constants.unitThreeAdFrameThreeId]
                })
        ),

        getUnitThreeTryOnInteractionsFrameOne: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_ONE,
                {
                    timeRange: timeRange,
                    breakdownBy: "josephMarc4130",
                    verb: "Interacted",
                    ids: [Constants.unitThreeTryOnId]
                })
        ),


        getUnitThreeTryOnInteractionsFrameTwo: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_TWO,
                {
                    timeRange: timeRange,
                    breakdownBy: "kamDhillon",
                    verb: "Interacted",
                    ids: [Constants.unitThreeTryOnId]
                })
        ),


        getUnitThreeTryOnInteractionsFrameThree: (timeRange: ActionPayloads.TimeRange = {}) => dispatch(
            makeAction(Actions.GET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_THREE,
                {
                    timeRange: timeRange,
                    breakdownBy: "rayBan",
                    verb: "Interacted",
                    ids: [Constants.unitThreeTryOnId]
                })
        ),


    }
}

export const AdsDashboard = connect(mapStateToProps, mapDispatchToProps)(AdsDashboardView)