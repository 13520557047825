import React from "react";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./index.css";

export const NavBar: React.FC = () => {
    return <Navbar bg="light" expand="lg" fixed="top">
        <Navbar.Brand><Link to="/">NexTechAR Ad Analytics</Link></Navbar.Brand>
    </Navbar>
}