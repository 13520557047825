import React from 'react';
import './App.css';

import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import { Container, Spinner } from "react-bootstrap"
import { NavBar } from "./Widgets/NavBar";
import { Footer } from "./Widgets/Footer";
import { SessionCheck } from './Widgets/SessionCheck';

import { SelectDashboard } from './Pages/SelectDashboard';
import { AdsDashboard } from './Pages/AdsDashboard';
import { PageNotFound } from './Pages/PageNotFound';
import { Login } from './Pages/Login'

import { Helmet } from "react-helmet";

require('./App.css');
require('bootstrap/dist/css/bootstrap.min.css');
require('react-vis/dist/style.css');

function App() {
  return (
    <div className="App">
      <Router>
        <SessionCheck showIf={"Auth"}>
          <>
            <NavBar />
            <Container fluid className="view">
              <Helmet>
                <title>NTAR Ad Analytics</title>
              </Helmet>
              <Switch>

                <Route exact path="/">
                  <SelectDashboard />
                </Route>

                <Route exact path="/nativetouch-clearly">
                  <Helmet>
                    <title>Native-touch - Tryon Carousel - Statistics</title>
                  </Helmet>
                  <AdsDashboard />
                </Route>

                <Route path="*">
                  <PageNotFound />
                </Route>

              </Switch>
            </Container>
            <Footer />
          </>
        </SessionCheck>

        <SessionCheck showIf="Guest">
          <Login />
        </SessionCheck>

        <SessionCheck showIf="Verifying">
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "50vh" }}>
            <Spinner animation="border" />
          </div>
        </SessionCheck>

      </Router>
    </div >
  );
}

export default App;
