
export const Constants = {
    xapi_url: "https://nexttech.lrs.io/dev/xapi",
    xapi_login: "iutrpcs",
    xapi_password: "pilajo",

    arc_url: process.env.NODE_ENV === "production" ? "https://api.nextechar.com" : "https://dev.api.nextechar.com",

    unitOneId: "tag:adlnet.gov,2013:expapi:0.9:activities:ca-gl-en-brnd-prsp-evnt-nth-othr-virual_ar",
    unitOneTryOnId: "https://assets.nextechar.com/embed/engine/tryon.2.0.1.caro-nt1/clients/nativetouch-clearly/CLEAR01_tryon.html",
    unitTwoId: "tag:adlnet.gov,2013:expapi:0.9:activities:ca-gl-en-brnd-prsp-evnt-nth-othr-geo_behavioural",
    unitTwoTryOnId: "https://assets.nextechar.com/embed/engine/tryon.2.0.1.caro-nt2/clients/nativetouch-clearly/CLEAR01_tryon.html",
    unitThreeId: "tag:adlnet.gov,2013:expapi:0.9:activities:ca-gl-en-brnd-prsp-evnt-nth-othr-user_app_detect",
    unitThreeTryOnId: "https://assets.nextechar.com/embed/engine/tryon.2.0.1.caro-nt3/clients/nativetouch-clearly/CLEAR01_tryon.html",

    unitOneAdFrameOneId: "https://ads.nextechar.com/clearly-caro-ad-unit-1-joseph-marc-4130",
    unitOneAdFrameTwoId: "https://ads.nextechar.com/clearly-caro-ad-unit-1-kam-dhillon",
    unitOneAdFrameThreeId: "https://ads.nextechar.com/clearly-caro-ad-unit-1-ray-ban",

    unitTwoAdFrameOneId: "https://ads.nextechar.com/clearly-caro-ad-unit-2-joseph-marc-4130",
    unitTwoAdFrameTwoId: "https://ads.nextechar.com/clearly-caro-ad-unit-2-kam-dhillon",
    unitTwoAdFrameThreeId: "https://ads.nextechar.com/clearly-caro-ad-unit-2-ray-ban",

    unitThreeAdFrameOneId: "https://ads.nextechar.com/clearly-caro-ad-unit-3-joseph-marc-4130",
    unitThreeAdFrameTwoId: "https://ads.nextechar.com/clearly-caro-ad-unit-3-kam-dhillon",
    unitThreeAdFrameThreeId: "https://ads.nextechar.com/clearly-caro-ad-unit-3-ray-ban",

}