import React from "react"
import { Table } from 'react-bootstrap'

import "./index.css";
import { AppState } from "@declarations";

interface Props {
    activity: AppState.TableRow[]
}
export const InteractionsTable: React.FC<Props> = (props) => {

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Impressions</th>
                    <th>AR Unit Opens</th>
                    <th>Time to Open</th>
                    <th>Clicks</th>
                    <th>Time in AR</th>
                    <th>Ad Interactions Frame 1 (Joseph Marc 4130)</th>
                    <th>Ad Interactions Frame 2 (Kam Dhillon)</th>
                    <th>Ad Interactions Frame 3 (Ray Ban)</th>
                    <th>Try-On Interactions Frame 1 (Joseph Marc 4130)</th>
                    <th>Try-On Interactions Frame 2 (Kam Dhillon)</th>
                    <th>Try-On Interactions Frame 3 (Ray Ban)</th>
                </tr>
            </thead>
            <tbody>
                {
                    props.activity.map((row, index) => (
                        <tr key={"unit-1-" + index.toString()}>
                            <td>{row.Date.toLocaleDateString()}</td>
                            <td>{row.Impressions ? row.Impressions : 0}</td>
                            <td>{row.ARUnitOpens ? row.ARUnitOpens : 0}</td>
                            <td>{row.TimeToOpen ? row.TimeToOpen.toFixed(2) : 0}</td>
                            <td>{row.Clicks ? row.Clicks : 0}</td>
                            <td>{row.TimeInAR ? row.TimeInAR.toFixed(2) : 0}</td>
                            <td>{row.AdInteractionsFrame1 ? row.AdInteractionsFrame1 : 0}</td>
                            <td>{row.AdInteractionsFrame2 ? row.AdInteractionsFrame2 : 0}</td>
                            <td>{row.AdInteractionsFrame3 ? row.AdInteractionsFrame3 : 0}</td>
                            <td>{row.TryOnInteractionsFrame1 ? row.TryOnInteractionsFrame1 : 0}</td>
                            <td>{row.TryOnInteractionsFrame2 ? row.TryOnInteractionsFrame2 : 0}</td>
                            <td>{row.TryOnInteractionsFrame3 ? row.TryOnInteractionsFrame3 : 0}</td>
                        </tr>
                    ))}
            </tbody>
        </Table>
    )

}

