import { all, takeLeading } from 'redux-saga/effects'
import { Actions } from '../Store/actions'
import {
  updatePeriodicData, updateOverviewData, updateAppActivity, updateAppOverview, updateAdsActivity,
  updateUnitOneImpressions,
  updateUnitOneOpens,
  updateUnitOneTimeToOpen,
  updateUnitOneClicks,
  updateUnitOneTimeInAr,
  updateUnitOneEmotions,
  // updateUnitOneAge,
  // updateUnitOneMale,
  // updateUnitOneFemale,
  // updateUnitOneSmile,
  // updateUnitOneAnger,
  // updateUnitOneContempt,
  // updateUnitOneDisgust,
  // updateUnitOneFear,
  // updateUnitOneHappiness,
  // updateUnitOneNeutral,
  // updateUnitOneSadness,
  // updateUnitOneSurprise,
  updateUnitOneAdInteractionsFrameOne,
  updateUnitOneAdInteractionsFrameTwo,
  updateUnitOneAdInteractionsFrameThree,
  updateUnitOneTryOnInteractionsFrameOne,
  updateUnitOneTryOnInteractionsFrameTwo,
  updateUnitOneTryOnInteractionsFrameThree,

  updateUnitTwoImpressions,
  updateUnitTwoOpens,
  updateUnitTwoTimeToOpen,
  updateUnitTwoClicks,
  updateUnitTwoTimeInAr,
  updateUnitTwoEmotions,
  // updateUnitTwoAge,
  // updateUnitTwoMale,
  // updateUnitTwoFemale,
  // updateUnitTwoSmile,
  // updateUnitTwoAnger,
  // updateUnitTwoContempt,
  // updateUnitTwoDisgust,
  // updateUnitTwoFear,
  // updateUnitTwoHappiness,
  // updateUnitTwoNeutral,
  // updateUnitTwoSadness,
  // updateUnitTwoSurprise,
  updateUnitTwoAdInteractionsFrameOne,
  updateUnitTwoAdInteractionsFrameTwo,
  updateUnitTwoAdInteractionsFrameThree,
  updateUnitTwoTryOnInteractionsFrameOne,
  updateUnitTwoTryOnInteractionsFrameTwo,
  updateUnitTwoTryOnInteractionsFrameThree,

  updateUnitThreeImpressions,
  updateUnitThreeOpens,
  updateUnitThreeTimeToOpen,
  updateUnitThreeClicks,
  updateUnitThreeTimeInAr,
  updateUnitThreeEmotions,
  // updateUnitThreeAge,
  // updateUnitThreeMale,
  // updateUnitThreeFemale,
  // updateUnitThreeSmile,
  // updateUnitThreeAnger,
  // updateUnitThreeContempt,
  // updateUnitThreeDisgust,
  // updateUnitThreeFear,
  // updateUnitThreeHappiness,
  // updateUnitThreeNeutral,
  // updateUnitThreeSadness,
  // updateUnitThreeSurprise,
  updateUnitThreeAdInteractionsFrameOne,
  updateUnitThreeAdInteractionsFrameTwo,
  updateUnitThreeAdInteractionsFrameThree,
  updateUnitThreeTryOnInteractionsFrameOne,
  updateUnitThreeTryOnInteractionsFrameTwo,
  updateUnitThreeTryOnInteractionsFrameThree,

} from "./analyticsSagas"
import { getEmbedDetails, getEmbeds } from "./embedSagas"
import { getBrands } from "./brandsSagas"
import { updateSessionSaga, loginSaga } from './sessionSaga'
import { getExperiences } from './appExperiencesSaga'

export function* rootSaga() {
  yield all([
    /**
     * @see https://redux-saga.js.org/docs/basics/UsingSagaHelpers.html
     */
    // Run the startup saga when the application starts
    // takeLatest(StartupTypes.STARTUP, startup),
    // Call `fetchUser()` when a `FETCH_USER` action is triggered
    takeLeading(Actions.GET_BRANDS, getBrands),
    // takeLeading(Actions.GET_BRAND_DETAILS, getBrandDetails),
    takeLeading(Actions.GET_EMBEDS, getEmbeds),
    takeLeading(Actions.GET_EMBED_DETAILS, getEmbedDetails),
    takeLeading(Actions.GET_PERIODIC_DATA, updatePeriodicData),
    takeLeading(Actions.GET_OVERVIEW_DATA, updateOverviewData),

    takeLeading(Actions.GET_APP_ACTIVITY, updateAppActivity),
    takeLeading(Actions.GET_APP_OVERVIEW, updateAppOverview),
    takeLeading(Actions.GET_EXPERIENCE_LIST, getExperiences),

    takeLeading(Actions.GET_ADS_ACTIVITY, updateAdsActivity),

    takeLeading(Actions.UPDATE_SESSION, updateSessionSaga),
    takeLeading(Actions.LOGIN, loginSaga),


    // ADS AND BREAKDOWNS
    takeLeading(Actions.GET_UNIT_ONE_IMPRESSIONS, updateUnitOneImpressions),
    takeLeading(Actions.GET_UNIT_ONE_OPENS, updateUnitOneOpens),
    takeLeading(Actions.GET_UNIT_ONE_TIME_TO_OPEN, updateUnitOneTimeToOpen),
    takeLeading(Actions.GET_UNIT_ONE_CLICKS, updateUnitOneClicks),
    takeLeading(Actions.GET_UNIT_ONE_TIME_IN_AR, updateUnitOneTimeInAr),
    takeLeading(Actions.GET_UNIT_ONE_EMOTIONS, updateUnitOneEmotions),
    // takeLeading(Actions.GET_UNIT_ONE_AGE, updateUnitOneAge),
    // takeLeading(Actions.GET_UNIT_ONE_MALE, updateUnitOneMale),
    // takeLeading(Actions.GET_UNIT_ONE_FEMALE, updateUnitOneFemale),
    // takeLeading(Actions.GET_UNIT_ONE_SMILE, updateUnitOneSmile),
    // takeLeading(Actions.GET_UNIT_ONE_ANGER, updateUnitOneAnger),
    // takeLeading(Actions.GET_UNIT_ONE_CONTEMPT, updateUnitOneContempt),
    // takeLeading(Actions.GET_UNIT_ONE_DISGUST, updateUnitOneDisgust),
    // takeLeading(Actions.GET_UNIT_ONE_FEAR, updateUnitOneFear),
    // takeLeading(Actions.GET_UNIT_ONE_HAPPINESS, updateUnitOneHappiness),
    // takeLeading(Actions.GET_UNIT_ONE_NEUTRAL, updateUnitOneNeutral),
    // takeLeading(Actions.GET_UNIT_ONE_SADNESS, updateUnitOneSadness),
    // takeLeading(Actions.GET_UNIT_ONE_SURPRISE, updateUnitOneSurprise),
    takeLeading(Actions.GET_UNIT_ONE_AD_INTERACTIONS_FRAME_ONE, updateUnitOneAdInteractionsFrameOne),
    takeLeading(Actions.GET_UNIT_ONE_AD_INTERACTIONS_FRAME_TWO, updateUnitOneAdInteractionsFrameTwo),
    takeLeading(Actions.GET_UNIT_ONE_AD_INTERACTIONS_FRAME_THREE, updateUnitOneAdInteractionsFrameThree),
    takeLeading(Actions.GET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_ONE, updateUnitOneTryOnInteractionsFrameOne),
    takeLeading(Actions.GET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_TWO, updateUnitOneTryOnInteractionsFrameTwo),
    takeLeading(Actions.GET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_THREE, updateUnitOneTryOnInteractionsFrameThree),

    takeLeading(Actions.GET_UNIT_TWO_IMPRESSIONS, updateUnitTwoImpressions),
    takeLeading(Actions.GET_UNIT_TWO_OPENS, updateUnitTwoOpens),
    takeLeading(Actions.GET_UNIT_TWO_TIME_TO_OPEN, updateUnitTwoTimeToOpen),
    takeLeading(Actions.GET_UNIT_TWO_CLICKS, updateUnitTwoClicks),
    takeLeading(Actions.GET_UNIT_TWO_TIME_IN_AR, updateUnitTwoTimeInAr),
        takeLeading(Actions.GET_UNIT_TWO_EMOTIONS, updateUnitTwoEmotions),
    // takeLeading(Actions.GET_UNIT_TWO_AGE, updateUnitTwoAge),
    // takeLeading(Actions.GET_UNIT_TWO_MALE, updateUnitTwoMale),
    // takeLeading(Actions.GET_UNIT_TWO_FEMALE, updateUnitTwoFemale),
    // takeLeading(Actions.GET_UNIT_TWO_SMILE, updateUnitTwoSmile),
    // takeLeading(Actions.GET_UNIT_TWO_ANGER, updateUnitTwoAnger),
    // takeLeading(Actions.GET_UNIT_TWO_CONTEMPT, updateUnitTwoContempt),
    // takeLeading(Actions.GET_UNIT_TWO_DISGUST, updateUnitTwoDisgust),
    // takeLeading(Actions.GET_UNIT_TWO_FEAR, updateUnitTwoFear),
    // takeLeading(Actions.GET_UNIT_TWO_HAPPINESS, updateUnitTwoHappiness),
    // takeLeading(Actions.GET_UNIT_TWO_NEUTRAL, updateUnitTwoNeutral),
    // takeLeading(Actions.GET_UNIT_TWO_SADNESS, updateUnitTwoSadness),
    // takeLeading(Actions.GET_UNIT_TWO_SURPRISE, updateUnitTwoSurprise),
    takeLeading(Actions.GET_UNIT_TWO_AD_INTERACTIONS_FRAME_ONE, updateUnitTwoAdInteractionsFrameOne),
    takeLeading(Actions.GET_UNIT_TWO_AD_INTERACTIONS_FRAME_TWO, updateUnitTwoAdInteractionsFrameTwo),
    takeLeading(Actions.GET_UNIT_TWO_AD_INTERACTIONS_FRAME_THREE, updateUnitTwoAdInteractionsFrameThree),
    takeLeading(Actions.GET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_ONE, updateUnitTwoTryOnInteractionsFrameOne),
    takeLeading(Actions.GET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_TWO, updateUnitTwoTryOnInteractionsFrameTwo),
    takeLeading(Actions.GET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_THREE, updateUnitTwoTryOnInteractionsFrameThree),

    takeLeading(Actions.GET_UNIT_THREE_IMPRESSIONS, updateUnitThreeImpressions),
    takeLeading(Actions.GET_UNIT_THREE_OPENS, updateUnitThreeOpens),
    takeLeading(Actions.GET_UNIT_THREE_TIME_TO_OPEN, updateUnitThreeTimeToOpen),
    takeLeading(Actions.GET_UNIT_THREE_CLICKS, updateUnitThreeClicks),
    takeLeading(Actions.GET_UNIT_THREE_TIME_IN_AR, updateUnitThreeTimeInAr),
        takeLeading(Actions.GET_UNIT_THREE_EMOTIONS, updateUnitThreeEmotions),
    // takeLeading(Actions.GET_UNIT_THREE_AGE, updateUnitThreeAge),
    // takeLeading(Actions.GET_UNIT_THREE_MALE, updateUnitThreeMale),
    // takeLeading(Actions.GET_UNIT_THREE_FEMALE, updateUnitThreeFemale),
    // takeLeading(Actions.GET_UNIT_THREE_SMILE, updateUnitThreeSmile),
    // takeLeading(Actions.GET_UNIT_THREE_ANGER, updateUnitThreeAnger),
    // takeLeading(Actions.GET_UNIT_THREE_CONTEMPT, updateUnitThreeContempt),
    // takeLeading(Actions.GET_UNIT_THREE_DISGUST, updateUnitThreeDisgust),
    // takeLeading(Actions.GET_UNIT_THREE_FEAR, updateUnitThreeFear),
    // takeLeading(Actions.GET_UNIT_THREE_HAPPINESS, updateUnitThreeHappiness),
    // takeLeading(Actions.GET_UNIT_THREE_NEUTRAL, updateUnitThreeNeutral),
    // takeLeading(Actions.GET_UNIT_THREE_SADNESS, updateUnitThreeSadness),
    // takeLeading(Actions.GET_UNIT_THREE_SURPRISE, updateUnitThreeSurprise),
    takeLeading(Actions.GET_UNIT_THREE_AD_INTERACTIONS_FRAME_ONE, updateUnitThreeAdInteractionsFrameOne),
    takeLeading(Actions.GET_UNIT_THREE_AD_INTERACTIONS_FRAME_TWO, updateUnitThreeAdInteractionsFrameTwo),
    takeLeading(Actions.GET_UNIT_THREE_AD_INTERACTIONS_FRAME_THREE, updateUnitThreeAdInteractionsFrameThree),
    takeLeading(Actions.GET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_ONE, updateUnitThreeTryOnInteractionsFrameOne),
    takeLeading(Actions.GET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_TWO, updateUnitThreeTryOnInteractionsFrameTwo),
    takeLeading(Actions.GET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_THREE, updateUnitThreeTryOnInteractionsFrameThree),


  ])
}
