import React from 'react';
import { Session as SessionType, AppState, PageProps } from "@declarations";
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

type Props = PageProps<typeof mapStatesToProps> & {
    showIf: SessionType.State | "HasAccess",
    children: React.ReactElement,
    else?: ((session: SessionType) => React.ReactElement) | React.ReactElement
    // else?: 
}
const SessionComponent: React.FC<Props> = ({ showIf, children, session, else: _else }) => {
    let { brand } = useParams<{ brand: string }>();

    
    if (session.state === showIf)
        return children
    else if (session.state === "Auth") {
        if (showIf === "HasAccess") {
            if (session.user.admin) return children;
            if (!session.user.admin && brand === session.user.brandName) { return children; }
        }
    }

    if (typeof _else === "function") return _else(session);
    return _else || null;
}

function mapStatesToProps(state: AppState) {
    return { session: state.session }
}
export const SessionCheck = connect(mapStatesToProps)(SessionComponent)