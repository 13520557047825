
import { AppState, ReduxAction, Session } from "@declarations";
import { Actions } from "../actions";

type AppContentState = Omit<AppState, "uiStates">;

export const AppContentReducer = (state: AppContentState, { type, payload }: ReduxAction, unit?: string, field?: string): AppContentState => {
    console.log("reducing", type, payload)
    if (type === Actions.SET_PERIODIC_DATA_WAIT_STATE) return { ...state, waiting: payload as boolean }
    if (type === Actions.SET_SESSION) return { ...state, session: payload as Session }
    if (type === Actions.SET_BRANDS_LIST) return { ...state, brandsList: payload as AppState.Brand[] }
    if (type === Actions.SET_APP_BRANDS_LIST) return { ...state, appBrandsList: payload as AppState.AppBrand[] }
    if (type === Actions.SET_EMBEDS_LIST) return { ...state, embedsListInView: payload as AppState.Embed[] }
    if (type === Actions.SET_EXPERIENCE_LIST) return { ...state, experienceList: payload as AppState.Experience[] }
    if (type === Actions.SET_CURRENT_BRAND) return { ...state, brandInView: payload as AppState.Brand }
    if (type === Actions.SET_CURRENT_EMBED) return { ...state, embedInView: payload as AppState.Embed }
    if (type === Actions.SET_CURRENT_EXPERIENCE) return { ...state, experienceInView: payload as AppState.Experience }
    if (type === Actions.SET_CURRENT_APP_BRAND) return { ...state, appBrandInView: payload as AppState.AppBrand }
    if (type === Actions.SET_CURRENT_CHANNEL) return { ...state, channelInView: payload as AppState.Channel }
    if (type === Actions.SET_EXP_IDS) return { ...state, expIds: payload as string[] }
    if (type === Actions.SET_DASHBOARD_OVERVIEW_DATA) {
        const { periodChartData, ...dashboardData } = payload as AppState.DashboardData; // omit periodic data, typescript doesnot chceck for too many fields
        return {
            ...state, dashboardData: {
                ...state.dashboardData,
                ...dashboardData
            }
        }
    }
    if (type === Actions.SET_DASHBOARD_PERIODIC_DATA) {
        const periodChartData = payload as AppState.DashboardData["periodChartData"];
        return {
            ...state, dashboardData: {
                ...state.dashboardData, periodChartData
            }
        }
    }

    if (type === Actions.SET_APP_ACTIVITY) {
        const activity = payload as AppState.AppDashboardData["activity"];
        return {
            ...state, appDashboardData: {
                ...state.appDashboardData, activity
            }
        }
    }
    if (type === Actions.SET_APP_OVERVIEW) {
        const overview = payload as AppState.AppDashboardData["overview"];
        return {
            ...state, appDashboardData: {
                ...state.appDashboardData, overview
            }
        }
    }

    if (type === Actions.SET_ADS_OVERVIEW) {
        const overview = payload as AppState.AdsDashboardData["overview"];
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData, overview
            }
        }
    }

    if (type === Actions.SET_ADS_ACTIVITY) {
        const activity = payload as AppState.AdsDashboardData["activity"];
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData, activity
            }
        }
    }

    if (type === Actions.SET_UNIT_ONE_IMPRESSIONS) {
        const payloadArray = payload as Array<any>;
        let unitOneActivity = state.adsDashboardData.unitOneActivity
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {

            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Impressions = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.Impressions = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_ONE_OPENS) {
        const payloadArray = payload as Array<any>;
        let unitOneActivity = state.adsDashboardData.unitOneActivity
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.ARUnitOpens = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.ARUnitOpens = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_ONE_TIME_TO_OPEN) {
        const payloadArray = payload as Array<any>;
        let unitOneActivity = state.adsDashboardData.unitOneActivity
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.TimeToOpen = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.TimeToOpen = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_ONE_CLICKS) {
        let unitOneActivity = state.adsDashboardData.unitOneActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Clicks = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Clicks = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_ONE_TIME_IN_AR) {
        let unitOneActivity = state.adsDashboardData.unitOneActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.TimeInAR = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.TimeInAR = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_ONE_EMOTIONS) {
        const payloadArray = payload as Array<any>;
        let updatedEmotions: AppState.AdsDashboardData["unitOneEmotions"] = [];
        payloadArray.forEach(item => {

            let row: AppState.EmotionsTableRow = {} as AppState.EmotionsTableRow;

            if (item.EXT) {

                const timestamp = item.statement && item.statement.timestamp ? new Date(item.statement.timestamp) : new Date();
            
                row.Date = timestamp;

                const extBase: string = "https://arecomm.nextechar.com/extension/"

                row.Age = item.EXT[extBase + "age"]
                row.Gender = item.EXT[extBase + "gender"].charAt(0).toUpperCase() + item.EXT[extBase + "gender"].slice(1);
                row.Smile = item.EXT[extBase + "smile"]
                row.Anger = item.EXT[extBase + "anger"]
                row.Contempt = item.EXT[extBase + "contempt"]
                row.Disgust = item.EXT[extBase + "digust"]
                row.Fear = item.EXT[extBase + "fear"]
                row.Happiness = item.EXT[extBase + "happiness"]
                row.Neutral = item.EXT[extBase + "neutral"]
                row.Sadness = item.EXT[extBase + "sadness"]
                row.Surprise = item.EXT[extBase + "surprise"]

                updatedEmotions.push(row);
            }

        })
        updatedEmotions.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneEmotions: updatedEmotions
            }
        }
    }

    /*
    if (type === Actions.SET_UNIT_ONE_AGE) {
        let unitOneActivity = state.adsDashboardData.unitOneActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Age = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Age = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_ONE_MALE) {
        let unitOneActivity = state.adsDashboardData.unitOneActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Male = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Male = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }


    if (type === Actions.SET_UNIT_ONE_FEMALE) {
        let unitOneActivity = state.adsDashboardData.unitOneActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Female = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Female = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }



    if (type === Actions.SET_UNIT_ONE_SMILE) {
        let unitOneActivity = state.adsDashboardData.unitOneActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Smile = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Smile = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_ONE_ANGER) {
        let unitOneActivity = state.adsDashboardData.unitOneActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Anger = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Anger = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_ONE_CONTEMPT) {
        let unitOneActivity = state.adsDashboardData.unitOneActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Contempt = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Contempt = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_ONE_DISGUST) {
        let unitOneActivity = state.adsDashboardData.unitOneActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Disgust = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Disgust = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_ONE_FEAR) {
        let unitOneActivity = state.adsDashboardData.unitOneActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Fear = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Fear = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_ONE_HAPPINESS) {
        let unitOneActivity = state.adsDashboardData.unitOneActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Happiness = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Happiness = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_ONE_NEUTRAL) {
        let unitOneActivity = state.adsDashboardData.unitOneActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Neutral = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Neutral = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_ONE_SADNESS) {
        let unitOneActivity = state.adsDashboardData.unitOneActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Sadness = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Sadness = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_ONE_SURPRISE) {
        let unitOneActivity = state.adsDashboardData.unitOneActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Surprise = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Surprise = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }
    */

    if (type === Actions.SET_UNIT_ONE_AD_INTERACTIONS_FRAME_ONE) {
        const payloadArray = payload as Array<any>;
        let unitOneActivity = state.adsDashboardData.unitOneActivity
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {

            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.AdInteractionsFrame1 = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.AdInteractionsFrame1 = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_ONE_AD_INTERACTIONS_FRAME_TWO) {
        const payloadArray = payload as Array<any>;
        let unitOneActivity = state.adsDashboardData.unitOneActivity
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {

            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.AdInteractionsFrame2 = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.AdInteractionsFrame2 = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }
    if (type === Actions.SET_UNIT_ONE_AD_INTERACTIONS_FRAME_THREE) {
        const payloadArray = payload as Array<any>;
        let unitOneActivity = state.adsDashboardData.unitOneActivity
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {

            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.AdInteractionsFrame3 = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.AdInteractionsFrame3 = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_ONE) {
        const payloadArray = payload as Array<any>;
        let unitOneActivity = state.adsDashboardData.unitOneActivity
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {

            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.TryOnInteractionsFrame1 = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.TryOnInteractionsFrame1 = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_TWO) {
        const payloadArray = payload as Array<any>;
        let unitOneActivity = state.adsDashboardData.unitOneActivity
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {

            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.TryOnInteractionsFrame2 = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.TryOnInteractionsFrame2 = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_THREE) {
        const payloadArray = payload as Array<any>;
        let unitOneActivity = state.adsDashboardData.unitOneActivity
        let updatedActivity: AppState.AdsDashboardData["unitOneActivity"] = [];
        payloadArray.forEach(item => {

            let row = unitOneActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.TryOnInteractionsFrame3 = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.TryOnInteractionsFrame3 = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitOneActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_TWO_IMPRESSIONS) {
        let unitTwoActivity = state.adsDashboardData.unitTwoActivity
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitTwoActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitTwoActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Impressions = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.Impressions = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_TWO_OPENS) {
        let unitTwoActivity = state.adsDashboardData.unitTwoActivity
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitTwoActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitTwoActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.ARUnitOpens = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.ARUnitOpens = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_TWO_TIME_TO_OPEN) {
        const payloadArray = payload as Array<any>;
        let unitTwoActivity = state.adsDashboardData.unitTwoActivity
        let updatedActivity: AppState.AdsDashboardData["unitTwoActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitTwoActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.TimeToOpen = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.TimeToOpen = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_TWO_CLICKS) {
        let unitTwoActivity = state.adsDashboardData.unitTwoActivity
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitTwoActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitTwoActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Clicks = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.Clicks = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_TWO_TIME_IN_AR) {
        let unitTwoActivity = state.adsDashboardData.unitTwoActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitTwoActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitTwoActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.TimeInAR = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.TimeInAR = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoActivity: updatedActivity
            }
        }
    }


    if (type === Actions.SET_UNIT_TWO_EMOTIONS) {
        const payloadArray = payload as Array<any>;
        let updatedEmotions: AppState.AdsDashboardData["unitTwoEmotions"] = [];
        payloadArray.forEach(item => {

            let row: AppState.EmotionsTableRow = {} as AppState.EmotionsTableRow;

            if (item.EXT) {

                const timestamp = item.statement && item.statement.timestamp ? new Date(item.statement.timestamp) : new Date();
            
                row.Date = timestamp;

                const extBase: string = "https://arecomm.nextechar.com/extension/"

                row.Age = item.EXT[extBase + "age"]
                row.Gender = item.EXT[extBase + "gender"].charAt(0).toUpperCase() + item.EXT[extBase + "gender"].slice(1);
                row.Smile = item.EXT[extBase + "smile"]
                row.Anger = item.EXT[extBase + "anger"]
                row.Contempt = item.EXT[extBase + "contempt"]
                row.Disgust = item.EXT[extBase + "digust"]
                row.Fear = item.EXT[extBase + "fear"]
                row.Happiness = item.EXT[extBase + "happiness"]
                row.Neutral = item.EXT[extBase + "neutral"]
                row.Sadness = item.EXT[extBase + "sadness"]
                row.Surprise = item.EXT[extBase + "surprise"]

                updatedEmotions.push(row);
            }

        })
        updatedEmotions.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoEmotions: updatedEmotions
            }
        }
    }

    /*
    if (type === Actions.SET_UNIT_TWO_AGE) {
        let unitTwoActivity = state.adsDashboardData.unitTwoActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitTwoActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitTwoActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Age = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Age = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoActivity: updatedActivity
            }
        }
    }


    if (type === Actions.SET_UNIT_TWO_MALE) {
        let unitTwoActivity = state.adsDashboardData.unitTwoActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitTwoActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitTwoActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Male = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Male = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoActivity: updatedActivity
            }
        }
    }


    if (type === Actions.SET_UNIT_TWO_FEMALE) {
        let unitTwoActivity = state.adsDashboardData.unitTwoActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitTwoActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitTwoActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Female = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Female = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoActivity: updatedActivity
            }
        }
    }


    if (type === Actions.SET_UNIT_TWO_SMILE) {
        let unitTwoActivity = state.adsDashboardData.unitTwoActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitTwoActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitTwoActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Smile = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Smile = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_TWO_ANGER) {
        let unitTwoActivity = state.adsDashboardData.unitTwoActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitTwoActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitTwoActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Anger = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Anger = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_TWO_CONTEMPT) {
        let unitTwoActivity = state.adsDashboardData.unitTwoActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitTwoActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitTwoActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Contempt = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Contempt = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_TWO_DISGUST) {
        let unitTwoActivity = state.adsDashboardData.unitTwoActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitTwoActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitTwoActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Disgust = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Disgust = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_TWO_FEAR) {
        let unitTwoActivity = state.adsDashboardData.unitTwoActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitTwoActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitTwoActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Fear = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Fear = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_TWO_HAPPINESS) {
        let unitTwoActivity = state.adsDashboardData.unitTwoActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitTwoActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitTwoActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Happiness = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Happiness = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_TWO_NEUTRAL) {
        let unitTwoActivity = state.adsDashboardData.unitTwoActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitTwoActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitTwoActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Neutral = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Neutral = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_TWO_SADNESS) {
        let unitTwoActivity = state.adsDashboardData.unitTwoActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitTwoActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitTwoActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Sadness = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Sadness = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_TWO_SURPRISE) {
        let unitTwoActivity = state.adsDashboardData.unitTwoActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitTwoActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitTwoActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Surprise = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Surprise = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoActivity: updatedActivity
            }
        }
    }

    */

    if (type === Actions.SET_UNIT_TWO_AD_INTERACTIONS_FRAME_ONE) {
        const payloadArray = payload as Array<any>;
        let unitTwoActivity = state.adsDashboardData.unitTwoActivity
        let updatedActivity: AppState.AdsDashboardData["unitTwoActivity"] = [];
        payloadArray.forEach(item => {

            let row = unitTwoActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.AdInteractionsFrame1 = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.AdInteractionsFrame1 = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_TWO_AD_INTERACTIONS_FRAME_TWO) {
        const payloadArray = payload as Array<any>;
        let unitTwoActivity = state.adsDashboardData.unitTwoActivity
        let updatedActivity: AppState.AdsDashboardData["unitTwoActivity"] = [];
        payloadArray.forEach(item => {

            let row = unitTwoActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.AdInteractionsFrame2 = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.AdInteractionsFrame2 = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoActivity: updatedActivity
            }
        }
    }
    if (type === Actions.SET_UNIT_TWO_AD_INTERACTIONS_FRAME_THREE) {
        const payloadArray = payload as Array<any>;
        let unitTwoActivity = state.adsDashboardData.unitTwoActivity
        let updatedActivity: AppState.AdsDashboardData["unitTwoActivity"] = [];
        payloadArray.forEach(item => {

            let row = unitTwoActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.AdInteractionsFrame3 = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.AdInteractionsFrame3 = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoActivity: updatedActivity
            }
        }
    }


    if (type === Actions.SET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_ONE) {
        const payloadArray = payload as Array<any>;
        let unitTwoActivity = state.adsDashboardData.unitTwoActivity
        let updatedActivity: AppState.AdsDashboardData["unitTwoActivity"] = [];
        payloadArray.forEach(item => {

            let row = unitTwoActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.TryOnInteractionsFrame1 = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.TryOnInteractionsFrame1 = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_TWO) {
        const payloadArray = payload as Array<any>;
        let unitTwoActivity = state.adsDashboardData.unitTwoActivity
        let updatedActivity: AppState.AdsDashboardData["unitTwoActivity"] = [];
        payloadArray.forEach(item => {

            let row = unitTwoActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.TryOnInteractionsFrame2 = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.TryOnInteractionsFrame2 = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitTwoActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_THREE) {
        const payloadArray = payload as Array<any>;
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {

            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.TryOnInteractionsFrame3 = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.TryOnInteractionsFrame3 = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_THREE_IMPRESSIONS) {
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Impressions = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.Impressions = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_THREE_OPENS) {
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.ARUnitOpens = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.ARUnitOpens = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_THREE_TIME_TO_OPEN) {
        const payloadArray = payload as Array<any>;
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.TimeToOpen = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.TimeToOpen = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_THREE_CLICKS) {
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Clicks = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.Clicks = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_THREE_TIME_IN_AR) {
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.TimeInAR = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.TimeInAR = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_THREE_EMOTIONS) {
        const payloadArray = payload as Array<any>;
        let updatedEmotions: AppState.AdsDashboardData["unitThreeEmotions"] = [];
        payloadArray.forEach(item => {

            let row: AppState.EmotionsTableRow = {} as AppState.EmotionsTableRow;

            if (item.EXT) {
                
                const time = item.statement && item.statement.timestamp ? new Date(item.statement.timestamp) : new Date();
            
                row.Date = time;

                const extBase: string = "https://arecomm.nextechar.com/extension/"

                row.Age = item.EXT[extBase + "age"]
                row.Gender = item.EXT[extBase + "gender"].charAt(0).toUpperCase() + item.EXT[extBase + "gender"].slice(1);
                row.Smile = item.EXT[extBase + "smile"]
                row.Anger = item.EXT[extBase + "anger"]
                row.Contempt = item.EXT[extBase + "contempt"]
                row.Disgust = item.EXT[extBase + "digust"]
                row.Fear = item.EXT[extBase + "fear"]
                row.Happiness = item.EXT[extBase + "happiness"]
                row.Neutral = item.EXT[extBase + "neutral"]
                row.Sadness = item.EXT[extBase + "sadness"]
                row.Surprise = item.EXT[extBase + "surprise"]

                updatedEmotions.push(row);
            }

        })
        updatedEmotions.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeEmotions: updatedEmotions
            }
        }
    }

    /*
    if (type === Actions.SET_UNIT_THREE_AGE) {
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Age = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Age = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }


    if (type === Actions.SET_UNIT_THREE_MALE) {
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Male = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Male = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }


    if (type === Actions.SET_UNIT_THREE_FEMALE) {
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Female = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Female = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_THREE_SMILE) {
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Smile = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Smile = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_THREE_ANGER) {
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Anger = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Anger = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_THREE_CONTEMPT) {
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Contempt = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Contempt = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_THREE_DISGUST) {
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Disgust = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Disgust = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_THREE_FEAR) {
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Fear = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Fear = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_THREE_HAPPINESS) {
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Happiness = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Happiness = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_THREE_NEUTRAL) {
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Neutral = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Neutral = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_THREE_SADNESS) {
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Sadness = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Sadness = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_THREE_SURPRISE) {
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity;
        const payloadArray = payload as Array<any>;
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {
            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.Surprise = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance)
                newRow.Surprise = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }

    */

    if (type === Actions.SET_UNIT_THREE_AD_INTERACTIONS_FRAME_ONE) {
        const payloadArray = payload as Array<any>;
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {

            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.AdInteractionsFrame1 = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.AdInteractionsFrame1 = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_THREE_AD_INTERACTIONS_FRAME_TWO) {
        const payloadArray = payload as Array<any>;
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {

            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.AdInteractionsFrame2 = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.AdInteractionsFrame2 = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }
    if (type === Actions.SET_UNIT_THREE_AD_INTERACTIONS_FRAME_THREE) {
        const payloadArray = payload as Array<any>;
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {

            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.AdInteractionsFrame3 = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.AdInteractionsFrame3 = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }


    if (type === Actions.SET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_ONE) {
        const payloadArray = payload as Array<any>;
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {

            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.TryOnInteractionsFrame1 = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.TryOnInteractionsFrame1 = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_TWO) {
        const payloadArray = payload as Array<any>;
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {

            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.TryOnInteractionsFrame2 = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.TryOnInteractionsFrame2 = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }

    if (type === Actions.SET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_THREE) {
        const payloadArray = payload as Array<any>;
        let unitThreeActivity = state.adsDashboardData.unitThreeActivity
        let updatedActivity: AppState.AdsDashboardData["unitThreeActivity"] = [];
        payloadArray.forEach(item => {

            let row = unitThreeActivity.find(row => row.Date.getDate() === new Date(item.instance).getDate());
            if (row) {
                row.TryOnInteractionsFrame3 = item.count;
                updatedActivity.push(row);
            } else {
                let newRow: AppState.TableRow = {} as AppState.TableRow;
                newRow.Date = new Date(item.instance);
                newRow.TryOnInteractionsFrame3 = item.count
                updatedActivity.push(newRow);
            }
        })
        updatedActivity.sort((a, b) => {
            if (a.Date < b.Date) {
                return 1;
            }
            if (a.Date > b.Date) {
                return -1;
            }
            return 0;
        })
        return {
            ...state, adsDashboardData: {
                ...state.adsDashboardData,
                unitThreeActivity: updatedActivity
            }
        }
    }

    return state;
}