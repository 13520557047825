
import { Constants } from "../Values/constants"

import { AppState } from "@declarations";

let JWT = "";

export const login = async (login: string, password: string) => {
    const response = await fetch(`${Constants.arc_url}/accounts/auth`, {
        method: 'post',
        credentials: 'include',
        body: JSON.stringify({
            "email": login,
            "password": password
        }),
        headers: {
            "Content-Type": 'application/json'
        }
    })
    if (response.status !== 200) throw (await response.json())
    return response.json();
}

export const getSession = async () => {
    const response = await fetch(`${Constants.arc_url}/accounts/me`, {
        method: 'GET',
        credentials: 'include'
    });
    if (response.status !== 200) throw (await response.json())
    return response.json();
}

export const getBrandInfo = async (brandId: string) => {
    const response = await fetch(`${Constants.arc_url}/brands/${brandId}`, {
        method: 'GET',
        credentials: 'include'
    });
    if (response.status !== 200) throw (await response.json())
    return response.json();
}

export const getJWT = async () => {
    const response = await fetch(`${Constants.arc_url}/xapi/jwt`, {
        method: 'GET',
        credentials: 'include'
    })
    const { token } = await response.json();
    if (response.status !== 200) throw (await response.json())
    JWT = token;
}

export const getEmbedsSummary = async (): Promise<AppState.Embed[]> => {

    // const resp: Response = await fetch(`${Constants.arc_url}/embeds/summary?ignoreDemo=true`);
    const resp: Response = await fetch(`https://api.nextechar.com/embeds/summary?ignoreDemo=true`);
    return resp.json();
}

export const getExperiencesSummary = async (): Promise<AppState.Embed[]> => {

    // const resp: Response = await fetch(`${Constants.arc_url}/experiences/summary`);
    const resp: Response = await fetch(`https://api.nextechar.com/experiences/summary`);
    return resp.json();
}


export const getEmbed = async (uuid: string): Promise<AppState.EmbedDetails> => {
    const response = await fetch(`${Constants.arc_url}/embeds/${uuid}/public`, {
        method: 'GET',
        credentials: 'include'
    })
    if (!response.ok) throw new Error(response.statusText);
    const embed = await response.json();
    return embed;
}
export const xApi = async (query: Array<Record<string, any>>) => {

    // const JWT = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJ0ZXN0X3Rva2VuIiwiZXhwIjoxNTkwODgzMjAwMDAwLCJzdWIiOiIiLCJyZWFkIjp0cnVlLCJ3cml0ZSI6dHJ1ZSwiYWR2YW5jZWRRdWVyaWVzIjp0cnVlLCJsaW1pdGVkUmVhZCI6ZmFsc2UsImF1ZCI6Im5leHR0ZWNoLmxycy5pbyIsImlzcyI6Im1vcnVsYSIsImlhdCI6MTU4NDY0ODMyMX0.1yrFbS-JR5klmtDTE7gtanFzqmm0WhrUUDiHrm6GMhc";
    const headers = new Headers();
    // headers.set('Authorization', `Bearer ${JWT}`);
    headers.set('Authorization', 'Basic ' + btoa(Constants.xapi_login + ":" + Constants.xapi_password));
    headers.set('Content-Type', 'application/json');

    const response = await fetch(`${Constants.xapi_url}/statements/aggregate`, {
        method: 'post', headers,
        body: JSON.stringify(query)
    })
    if (response.status !== 200) {
        // if (response.status === 401) JWT = "";
        throw (await response.json());
    }

    return response.json();
}
