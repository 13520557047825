import { put, all, take } from 'redux-saga/effects'
import { ReduxAction, AppState, ActionPayloads, Session } from '@declarations';
import { getGlobalOverviewQry, getGlobalOpensByDay, getUriOpensByDay, getUriOverview, getCartAddsByPeriod } from "../Utils/webQueries"
import { XapiKeys, AppDataParams, AppActivityParams, WithScope, AdsDataParams } from '../Values/xapiKeys';
import { xApi } from '../Utils/RemoteCalls';
import { makeAction, Actions } from '../Store/actions';
import { InitState } from '../Store/Reducers/index';
import { store } from '../Store';
import { getAppOverview, getAppActivity, getAdsActivity } from '../Utils/appQueries'

function parseOverviewData(data: any[]) {
    let dwellCount = 0;
    const emptyDashData = JSON.parse(JSON.stringify(InitState.dashboardData)) as AppState.DashboardData;

    function getDeviceType(value: string, lookIn: "Definitions" | "Extensions") {
        if (lookIn === "Definitions") {
            const knownDevices: Array<keyof typeof XapiKeys.DeviceMapping.toDefinitions> = ["Mobile", "Desktop", "AR"]
            const device = knownDevices.find(device => {
                return XapiKeys.DeviceMapping.toDefinitions[device].some((item) => {
                    const defId = item as keyof typeof XapiKeys.Definitions
                    return (XapiKeys.Definitions[defId] === value)
                })

            });
            return device;
        } else {
            const knownDevices: Array<keyof typeof XapiKeys.DeviceMapping.toExtType> = ["Mobile", "Desktop"]
            const device = knownDevices.find(device => {
                return XapiKeys.DeviceMapping.toExtType[device].some((item) => {
                    return (item === value)
                })
            });
            return device;

        }
    }

    const { periodChartData, ...placeholder } = { ...emptyDashData };
    // const placeholder = {
    //     dwellTime: 0,
    //     dwellAvg: 0,
    // }
    const parsedData = data.reduce<typeof placeholder>((res, item: any) => {
        if (item._id.verb === XapiKeys.Verbs.complete) {
            res.dwellTime = (res.dwellTime || 0) + item.totalDuration;
            dwellCount = dwellCount + item.count;
        }
        if (item._id.verb === XapiKeys.Verbs.open && item._id.defType) {
            const device = getDeviceType(item._id.defType, "Definitions");
            if (!device) return res;
            res.totalOpens = res.totalOpens || { ...emptyDashData.totalOpens };
            res.totalOpens.total += item.count
            res.totalOpens.breakdown[device] = (res.totalOpens.breakdown[device] || 0) + item.count;
        }
        if (item._id.verb === XapiKeys.Verbs.addToCart && item._id.defType) {
            const device = getDeviceType(item._id.defType, "Definitions");
            if (!device) return res;
            res.totalAddToCarts = res.totalAddToCarts || { ...emptyDashData.totalAddToCarts };
            res.totalAddToCarts.total += item.count
            res.totalAddToCarts.breakdown[device] = (res.totalAddToCarts.breakdown[device] || 0) + item.count;
        }
        if (item._id.verb === XapiKeys.Verbs.view && item._id.viewDevice) {
            const device = getDeviceType(item._id.viewDevice, "Extensions");
            if (!device) return res;
            res.totalViews = res.totalViews || { ...emptyDashData.totalViews };
            res.totalViews.total += item.count;
            res.totalViews.breakdown[device] = (res.totalViews.breakdown[device] || 0) + item.count;

        }
        return res;
    }, placeholder)
    // console.log("parsedData", parsedData, emptyDashData);
    parsedData.dwellAvg = (parsedData.dwellTime || 0) / (dwellCount || 1);
    parsedData.estDwellTime = parsedData.dwellAvg * parsedData.totalOpens.total;
    return parsedData;
}
export function* updateOverviewData({ payload }: ReduxAction<{ timeRange?: ActionPayloads.TimeRange, scopeFilter?: ActionPayloads.ScopeFilter }>) {
    yield put(makeAction(Actions.SET_OVERVIEW_WAIT_STATE, true));

    const fallbacks: Required<typeof payload> = {
        timeRange: {}, scopeFilter: { scope: "global", }
    }
    const payloadWithFallbacks: typeof fallbacks = { ...fallbacks, ...(payload || fallbacks) };
    const { timeRange, scopeFilter: { scope, id } } = payloadWithFallbacks;


    let query: Record<string, any>[] = [];
    if (scope === "global") query = getGlobalOverviewQry(timeRange);
    else if (scope === "brand") {
        // id is brand name
        if (!id) throw new Error("Calls with scope " + scope + " should include ids (brand name)")
        yield put(makeAction(Actions.GET_EMBEDS, { brand: id }));
        yield take(Actions.SET_EMBEDS_LIST);
        let embeds = store.getState().embedsListInView;

        query = getUriOverview(timeRange, embeds.map(embed => embed.pageUrl));

    } else if (scope === "embed") {
        // id is pageUrl
        if (!id) throw new Error("Calls with scope " + scope + " should include ids (pageUrl)")
        query = getUriOverview(timeRange, [id]);

    }

    const data: any[] = yield xApi(query)
    const parsedData = parseOverviewData(data)
    const dataToDispatch: AppState.DashboardData = {
        ...InitState.dashboardData, ...parsedData
    }
    // console.log(">>----", makeAction(Actions.SET_DASHBOARD_OVERVIEW_DATA, dataToDispatch))
    yield put(makeAction(Actions.SET_DASHBOARD_OVERVIEW_DATA, dataToDispatch))
    yield put(makeAction(Actions.SET_OVERVIEW_WAIT_STATE, false));

}

export function* updatePeriodicData({ payload }: ReduxAction<{ timeRange?: ActionPayloads.TimeRange, scopeFilter?: ActionPayloads.ScopeFilter }>) {
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));
    const fallbacks: Required<typeof payload> = {
        timeRange: {}, scopeFilter: { scope: "global", }
    }
    const payloadWithFallbacks: typeof fallbacks = { ...fallbacks, ...(payload || fallbacks) };
    const { timeRange, scopeFilter: { scope, id } } = payloadWithFallbacks;

    let opensQuery: Record<string, any>[] = [], cartAddsQuery: Record<string, any>[] = [];
    if (scope === "global") {
        opensQuery = getGlobalOpensByDay(timeRange);
        cartAddsQuery = getCartAddsByPeriod(timeRange, "global", []);
    }
    else if (scope === "brand") {
        //assume id is brand name
        //find uri's from name-mapping
        if (!id) throw new Error("Calls with scope " + scope + " should include ids (brand name)")
        yield put(makeAction(Actions.GET_EMBEDS, { brand: id }))
        yield take(Actions.SET_EMBEDS_LIST)
        let embeds = store.getState().embedsListInView;

        opensQuery = getUriOpensByDay(timeRange, scope, embeds.map(embed => embed.pageUrl));
        cartAddsQuery = getCartAddsByPeriod(timeRange, scope, embeds.map(embed => embed.pageUrl));
    } else if (scope === "embed") {
        if (!id) throw new Error("Calls with scope " + scope + " should include ids (pageUrls)")
        opensQuery = getUriOpensByDay(timeRange, scope, [id]);
        cartAddsQuery = getCartAddsByPeriod(timeRange, scope, [id]);
    }
    // console.log("periodicData", opensQuery);
    const [opensData, cartsData]: any[][] = yield all([xApi(opensQuery), xApi(cartAddsQuery)]);
    const opensDataToDispatch = opensData.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.openCount,
            instance: new Date(item._id)
        }
    });
    const cartsDataToDispatch = cartsData.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.openCount,
            instance: new Date(item._id)
        }
    })
    yield put(makeAction(Actions.SET_DASHBOARD_PERIODIC_DATA, { opensData: opensDataToDispatch, cartsData: cartsDataToDispatch }));
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));

}

function isAuthorized(scope: WithScope<{}>["scope"] | undefined) {
    const expId = scope?.experienceId
    const session = store.getState().session as Session.AuthSession;
    const brands = store.getState().appBrandsList;
    const brand = session.user.brandName;
    if (session.user.admin) return true;
    if (scope) {
        return scope.brand === brand
    }
    if (!expId) return false;
    return !!brands.find(
        brandItem => brandItem.name === brand && brandItem.channels.find(
            channel => channel.experiences.find(exp => exp.id === expId)
        )
    )

}

function* assureBrandlist() {
    const brands = store.getState().appBrandsList;
    if (brands && brands.length) return;
    yield put(makeAction(Actions.GET_EXPERIENCE_LIST))
    yield take(Actions.SET_APP_BRANDS_LIST)
}

function resolveAppScope(payload: WithScope<AppDataParams>) {
    let expIds: string[] | undefined;
    if (payload.scope) {
        const { brand, channel } = payload.scope;
        const brands = store.getState().appBrandsList;
        const brandObj = brands.find(item => item.name === brand);
        if (channel) {
            expIds = brandObj?.channels.find(item => item.name === channel)?.experiences.map(exp => exp.id);
        } else expIds = brandObj?.channels.reduce((arr, channel) => {
            channel.experiences.forEach(exp => arr.push(exp.id));
            return arr;
        }, [] as string[])

        if (expIds && payload.scope?.experienceId) {
            expIds = expIds.filter(id => id === payload.scope?.experienceId)
        }
    }
    const { breakdownBy, timeRange, verb } = payload;
    return { breakdownBy, timeRange, experienceIds: expIds?.length ? expIds : null, verb };
}

export function* updateAppOverview({ payload }: ReduxAction<WithScope<AppDataParams>>) {
    if (!payload) throw new Error("Params cannot be empty");
    yield put(makeAction(Actions.SET_OVERVIEW_WAIT_STATE, true));
    yield assureBrandlist();
    if (!isAuthorized(payload.scope)) {
        alert("not authorized");
        yield put(makeAction(Actions.SET_NOT_FOUND, true))
        return;
    }
    const resolvedPayload: AppActivityParams = yield resolveAppScope(payload);
    if (!resolvedPayload.experienceIds && payload.scope) {
        yield put(makeAction(Actions.SET_NOT_FOUND, true))
        return;
    }
    console.log("___", resolvedPayload, payload);
    const query = getAppOverview(resolvedPayload)

    const dataToDispatch: AppState.AppDashboardData["overview"] = yield xApi(query);
    yield put(makeAction(Actions.SET_APP_OVERVIEW, dataToDispatch))
    yield put(makeAction(Actions.SET_OVERVIEW_WAIT_STATE, false));
}

export function* updateAppActivity({ payload }: ReduxAction<WithScope<AppActivityParams>>) {
    console.log("calling update app activity");
    if (!payload) throw new Error("Params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));
    yield assureBrandlist();
    if (!isAuthorized(payload.scope)) {
        yield put(makeAction(Actions.SET_NOT_FOUND, true))
        return;
    }
    const resolvedPayload: AppActivityParams = yield resolveAppScope(payload);
    if (!resolvedPayload.experienceIds?.length && payload.scope) {
        yield put(makeAction(Actions.SET_NOT_FOUND, true))
        return;
    }
    const query = getAppActivity(resolvedPayload)

    const data: any[] = yield xApi(query)
    const dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    yield put(makeAction(Actions.SET_APP_ACTIVITY, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateAdsActivity({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    // const dataToDispatch: AppState.AdsDashboardData["activity"] = yield xApi(query);

    const data: any[] = yield xApi(query)
    const dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    yield put(makeAction(Actions.SET_ADS_ACTIVITY, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


// ADS ACTIVITY AND BREAKDOWNS

function fillEmptyData(dataToDispatch: AppState.PeriodChartSlice[], payload: AdsDataParams) {

    let currentDate = payload?.timeRange?.from;
    let endDate = payload?.timeRange?.to;

    if (currentDate && endDate) {
        while (currentDate <= endDate) {
            const found = dataToDispatch.find(item => item.instance.getDate() === currentDate?.getDate())
            if (!found) {
                dataToDispatch.push({
                    count: 0,
                    instance: new Date(currentDate)
                })
            }
            currentDate.setDate(currentDate.getDate() + 1)
        }
    }

    return dataToDispatch;
}


export function* updateUnitOneImpressions({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_IMPRESSIONS, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitOneOpens({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);


    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_OPENS, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitOneTimeToOpen({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);


    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_TIME_TO_OPEN, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitOneClicks({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);


    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_CLICKS, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitOneTimeInAr({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);


    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_TIME_IN_AR, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitOneEmotions({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)

    yield put(makeAction(Actions.SET_UNIT_ONE_EMOTIONS, data))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

/*
export function* updateUnitOneAge({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_AGE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitOneMale({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_MALE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitOneFemale({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_FEMALE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}




export function* updateUnitOneSmile({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_SMILE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitOneAnger({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_ANGER, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitOneContempt({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_CONTEMPT, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitOneDisgust({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_DISGUST, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitOneFear({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_FEAR, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitOneHappiness({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_HAPPINESS, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitOneNeutral({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_NEUTRAL, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitOneSadness({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_SADNESS, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitOneSurprise({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_SURPRISE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}
*/
export function* updateUnitOneAdInteractionsFrameOne({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_AD_INTERACTIONS_FRAME_ONE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitOneAdInteractionsFrameTwo({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_AD_INTERACTIONS_FRAME_TWO, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}
export function* updateUnitOneAdInteractionsFrameThree({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_AD_INTERACTIONS_FRAME_THREE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitOneTryOnInteractionsFrameOne({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_ONE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitOneTryOnInteractionsFrameTwo({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_TWO, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitOneTryOnInteractionsFrameThree({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_ONE_TRYON_INTERACTIONS_FRAME_THREE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitTwoImpressions({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);


    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload); 

    yield put(makeAction(Actions.SET_UNIT_TWO_IMPRESSIONS, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitTwoOpens({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);


    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_TWO_OPENS, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitTwoTimeToOpen({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);


    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_TWO_TIME_TO_OPEN, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitTwoClicks({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);


    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);
    
    yield put(makeAction(Actions.SET_UNIT_TWO_CLICKS, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitTwoTimeInAr({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);


    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_TWO_TIME_IN_AR, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitTwoEmotions({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)

    yield put(makeAction(Actions.SET_UNIT_TWO_EMOTIONS, data))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}
/*
export function* updateUnitTwoAge({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_TWO_AGE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitTwoMale({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_TWO_MALE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitTwoFemale({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_TWO_FEMALE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}



export function* updateUnitTwoSmile({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_TWO_SMILE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitTwoAnger({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_TWO_ANGER, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitTwoContempt({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_TWO_CONTEMPT, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitTwoDisgust({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_TWO_DISGUST, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitTwoFear({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_TWO_FEAR, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitTwoHappiness({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_TWO_HAPPINESS, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitTwoNeutral({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_TWO_NEUTRAL, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitTwoSadness({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_TWO_SADNESS, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitTwoSurprise({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_TWO_SURPRISE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}
*/

export function* updateUnitTwoAdInteractionsFrameOne({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_TWO_AD_INTERACTIONS_FRAME_ONE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitTwoAdInteractionsFrameTwo({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_TWO_AD_INTERACTIONS_FRAME_TWO, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}
export function* updateUnitTwoAdInteractionsFrameThree({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_TWO_AD_INTERACTIONS_FRAME_THREE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitTwoTryOnInteractionsFrameOne({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_ONE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitTwoTryOnInteractionsFrameTwo({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_TWO, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitTwoTryOnInteractionsFrameThree({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_TWO_TRYON_INTERACTIONS_FRAME_THREE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}



export function* updateUnitThreeImpressions({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);


    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_IMPRESSIONS, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitThreeOpens({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);


    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_OPENS, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitThreeTimeToOpen({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);


    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_TIME_TO_OPEN, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitThreeClicks({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);


    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_CLICKS, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}
export function* updateUnitThreeTimeInAr({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);


    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_TIME_IN_AR, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitThreeEmotions({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)

    yield put(makeAction(Actions.SET_UNIT_THREE_EMOTIONS, data))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}
/*
export function* updateUnitThreeAge({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_AGE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitThreeMale({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_MALE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitThreeFemale({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_FEMALE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}



export function* updateUnitThreeSmile({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_SMILE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitThreeAnger({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_ANGER, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitThreeContempt({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_CONTEMPT, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitThreeDisgust({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_DISGUST, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitThreeFear({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_FEAR, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitThreeHappiness({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_HAPPINESS, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitThreeNeutral({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_NEUTRAL, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitThreeSadness({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_SADNESS, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}


export function* updateUnitThreeSurprise({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_SURPRISE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

*/

export function* updateUnitThreeAdInteractionsFrameOne({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_AD_INTERACTIONS_FRAME_ONE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitThreeAdInteractionsFrameTwo({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_AD_INTERACTIONS_FRAME_TWO, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitThreeAdInteractionsFrameThree({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_AD_INTERACTIONS_FRAME_THREE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitThreeTryOnInteractionsFrameOne({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_ONE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitThreeTryOnInteractionsFrameTwo({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_TWO, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

export function* updateUnitThreeTryOnInteractionsFrameThree({ payload }: ReduxAction<AdsDataParams>) {

    if (!payload) throw new Error("Ads params cannot be empty");
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, true));

    const query = getAdsActivity(payload);

    const data: any[] = yield xApi(query)
    let dataToDispatch = data.map<AppState.PeriodChartSlice>(item => {
        return {
            count: item.count,
            instance: new Date(item._id)
        }
    })

    dataToDispatch = fillEmptyData(dataToDispatch, payload);

    yield put(makeAction(Actions.SET_UNIT_THREE_TRYON_INTERACTIONS_FRAME_THREE, dataToDispatch))
    yield put(makeAction(Actions.SET_PERIODIC_DATA_WAIT_STATE, false));
}

